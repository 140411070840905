import {QUERY_TYPES} from "../components/GraphView/GraphView";

export function makeChartData(data, queryNumber, mor, yearLabels) {
  const chartObject = {
    labels: labelPicker(data, queryNumber, yearLabels),
    datasets: dataSetPicker(data, queryNumber, mor, yearLabels),
  };

  return chartObject;
}

function labelPicker(data, queryNumber, yearLabels) {
  switch (queryNumber) {
    case 0:
    case 10:
    case QUERY_TYPES.graphAggregatedWeeksSeaTroutWholeRiver:
    case QUERY_TYPES.graphAggregatedWeeksSeaTroutZone:
      return makeWeekLabels(data);

    case 3:
    case 4:
    case 6:
    case 7:
    case 13:
    case 16:
    case 17:
      return yearLabels;

    default:
      return makeWeekLabels(data);
  }
}

function dataSetPicker(data, number, mor, yearLabels) {
  switch (number.toString()) {
    case "0":
    case "10":
      return makeNumberDataSetHeleElven(data, yearLabels, 'Laks');
    case QUERY_TYPES.graphAggregatedWeeksSeaTroutWholeRiver:
    case QUERY_TYPES.graphAggregatedWeeksSeaTroutZone:
      return makeNumberDataSetHeleElven(data, yearLabels, 'Sjøørret');

    case "1":
      return makeWeightDataSet(data);
    case "2":
      return makeWeightAndNumberDataSet(data);

    case "3":
    case "13":
      return makeArtComparisonDataSet(data, yearLabels);
    case "6":
    case "16":
      return makeCatchReleaseDataSet(data, yearLabels);
    case "7":
    case "17":
      return makeToolComparisonDataSet(data, yearLabels);

    default:
      return null;
  }
}

function makeWeekLabels(data) {
  if (typeof data === "undefined") return null;
  const weeks = data.map((catchAggregation) => catchAggregation?.Uke);
  const uniqueWeeks = [...new Set(weeks)];
  return uniqueWeeks.sort((a,b) => a-b);
}

function aggregateCatchDataForWeekYearAndSpecies(year, weeks, species, data) {
  return weeks.map(week => {
    const filtered = data.filter(d => d.Uke === week && d.Aar === year && d.Art.trim() === species);
    return filtered.reduce((sum, current) => sum + current.ant, 0);
  });
}

function makeNumberDataSetHeleElven(data, yearLabels, species) {
  const weeks = makeWeekLabels(data);
  return [
    {
      label: yearLabels[0],
      data: aggregateCatchDataForWeekYearAndSpecies(yearLabels[0], weeks, species, data),
      backgroundColor: "rgba(73, 97, 75, 1)"
    },
    {
      label: yearLabels[1],
      data: aggregateCatchDataForWeekYearAndSpecies(yearLabels[1], weeks, species, data),
      backgroundColor: "rgba(169, 166, 147, 1)"
    },
    {
      label: yearLabels[2],
      data: aggregateCatchDataForWeekYearAndSpecies(yearLabels[2], weeks, species, data),
      backgroundColor: "rgba(168, 127, 62, 1)"
    },
  ];
}

function makeWeightDataSet(data) {
  let dataset = [];
  data.forEach((week) => {
    dataset.push(week.KG);
  });
  const datasets = [
    {
      label: "Kg per uke",
      data: dataset,
      backgroundColor: "rgba(73, 97, 75, 1)",
    },
  ];

  return datasets;
}
function makeWeightAndNumberDataSet(data) {
  let datasetAnt = [];
  let datasetKG = [];
  data.forEach((week) => {
    datasetAnt.push(week.ant);
    datasetKG.push(week.KG);
  });
  const datasets = [
    {
      label: "Antall per uke",
      data: datasetAnt,
      backgroundColor: "rgba(73, 97, 75, 1)",
    },
    {
      label: "Kilo per uke",
      data: datasetKG,
      backgroundColor: "rgba(169, 166, 147, 1)",
    },
  ];

  return datasets;
}

function makeArtComparisonDataSet(data, yearLabels) {
  let datasetLaks = [];
  let datasetSjoroye = [];
  let datasetOrret = [];

  if (typeof data === "undefined") return null;

  let dataByYear = {};
  data.forEach(dataPoint => {
    dataByYear[dataPoint.YEAR] = dataPoint;
  });

  yearLabels.forEach(year => {
    // TODO: Possible species should probably be more dynamnic like in the lower table.
    let dataPoint = dataByYear[year] || { Laks: 0, SjoOrret: 0, SjoRoye: 0, YEAR: year };
    datasetLaks.push(dataPoint.Laks);
    datasetSjoroye.push(dataPoint.SjoRoye);
    datasetOrret.push(dataPoint.SjoOrret);
  });

  const datasets = [
    {
      label: "Laks",
      data: datasetLaks,
      backgroundColor: "rgba(73, 97, 75, 1)",
    },
    {
      label: "Sjøørret",
      data: datasetOrret,
      backgroundColor: "rgba(169, 166, 147, 1)",
    },

    {
      label: "Sjørøye",
      data: datasetSjoroye,
      backgroundColor: "rgba(168, 127, 62, 1)",
    },
  ];

  return datasets;
}
function makeToolComparisonDataSet(data, yearLabels) {
  const predefinedColors = ["rgba(73, 97, 75, 1)", "rgba(169, 166, 147, 1)", "rgba(168, 127, 62, 1)", "#3f463c", "#e6e7d5"]

  const tools = Array.from(new Set(data.map(toolWithCatch => toolWithCatch.toolName)))
    .map((tool, index) => {
      return {label: tool ?? "Ukjent", data: [], backgroundColor: predefinedColors[index % predefinedColors.length]}
    });

  if (typeof data === "undefined") return null;

  let dataByYear = {};
  data.forEach(dataPoint => {
    if (!dataByYear[dataPoint.year]) {
      const toolRecord = { year: dataPoint.year }
      toolRecord[dataPoint.toolName] = dataPoint.amount;
      dataByYear[dataPoint.year] = toolRecord;
    } else {
      const toolRecord = dataByYear[dataPoint.year];
      if (!toolRecord[dataPoint.toolName]) {
        toolRecord[dataPoint.toolName] = 0;
      }
      toolRecord[dataPoint.toolName] = toolRecord[dataPoint.toolName] + dataPoint.amount;
      dataByYear[dataPoint.year] = toolRecord;
    }
  });

  yearLabels.forEach(year => {
    let dataPoint = dataByYear[year] || { Flue: 0, Mark: 0, Sluk: 0, Notfiske: 0, Stang: 0, YEAR: year };
    tools.forEach(label => label.data.push(dataPoint[label.label === "Ukjent" ? null : label.label]));
  });
  return tools;
}

function makeCatchReleaseDataSet(data, yearLabels) {
  let datasetLaksReleased = [];
  let datasetLaksCatched = [];

  if (typeof data === "undefined") return null;

  let dataByYear = {};
  data.forEach(dataPoint => {
    dataByYear[dataPoint.YEAR] = dataPoint;
  });

  yearLabels.forEach((year) => {
    let dataPoint = dataByYear[year] || { Release: 0, Catch: 0, YEAR: year };
    datasetLaksReleased.push(dataPoint.Release);
    datasetLaksCatched.push(dataPoint.Catch);
  });

  const datasets = [
    {
      label: "Catch",
      data: datasetLaksCatched,
      backgroundColor: "rgba(73, 97, 75, 1)",
    },
    {
      label: "Release",
      data: datasetLaksReleased,
      backgroundColor: "rgba(169, 166, 147, 1)",
    },
  ];

  return datasets;
}
