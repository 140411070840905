import Filter from "../../images/filter.svg";
import "./TableFilters.css";

export const TableFiltersButton = (props) => {
    return (
        <div
            {...props}
            className={`form-inline my-2 my-lg-0 filter-button ${props.className}`}
        >
            <button className="button borderSetting filter-button__button">
                FILTER <img className="filter-button__icon" src={Filter} />
            </button>
        </div>
    );
};
