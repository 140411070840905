import React from "react";
import {Bar} from "react-chartjs-2";
import MediaQuery from "react-responsive";

export default function Chart({data}) {
  const datasetKeyProvider = () => {
    return btoa(Math.random()).substring(0, 12);
  };
  return (
    <div className='chart'>
      <MediaQuery minWidth={1224}>
        <div className='chartWeb'>
          <Bar
            data={data}
            datasetKeyProvider={datasetKeyProvider}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              legend: {
                position: "bottom", //this.props.legendPosition,
                labels: {
                  usePointStyle: true,
                  fontSize: 14,
                  padding: 25,
                },
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    ticks: {
                      min: 0,
                    },
                  },
                ],
              },
            }}
          ></Bar>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div className='chartMobile'>
          <Bar
            data={data}
            datasetKeyProvider={datasetKeyProvider}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              fontSize: 10,
              fullWidth: true,
              legend: {
                position: "bottom", //this.props.legendPosition,

                labels: {
                  usePointStyle: true,
                  fontSize: 10,
                  padding: 15,
                },
              },
              scales: {
                xAxes: [
                  {
                    display: true,
                    ticks: {
                      min: 0,
                    },
                  },
                ],
              },
            }}
          ></Bar>
        </div>
      </MediaQuery>
    </div>
  );
}
