import React, { Fragment, useState } from "react";
import { Query } from "@apollo/client/react/components";
import "./RiverTable.scss";
import { Link } from "react-router-dom";
import MediaQuery from "react-responsive";
import gql from "graphql-tag";

const RiverTable = ({
  countyId,
  searchString,
  limit,
  offset,
  paginateRight,
  paginateLeft,
}) => {
  const [dataLength, setDataLength] = useState(0);
  // Apollo query
  const FETCH_RIVERS = gql`
    query RiversQuery {
      rivers(
      ${countyId && countyId > 0 ? 'countyId:"' + countyId + '",' : ''}
      ${searchString ? 'nameSearch:"' + searchString.trim() + '",' : ''}
      offset:${offset},limit:${limit}
      ){
        ID
        NAVN
        ELVNAVN
        Mor
        fylkeID
        Url
        __typename
      }
    }`;
  return (
    <Fragment>
      <MediaQuery minWidth={1200}>
        <table className='riverTable table table-hover'>
          <thead>
            <tr>
              <th scope='col'>ELV</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <Query query={FETCH_RIVERS} fetchPolicy='no-cache' offset={offset}>
              {({ error, data }) => {
                if (error) console.log(error);

                if (data == undefined || data == null) {
                  return (
                    <tr key="0" className='loadingTable'>
                      <td>Kunne ikke hente data... Prøv å laste inn siden på nytt</td>
                    </tr>
                  );
                }

                setDataLength(data.rivers.length);

                if (dataLength === 0) return <div>Ingen treff</div>;

                return data.rivers.map((river) => {
                  return (
                    <tr key={river.ID}>
                      <td>{river.NAVN}</td>
                      <td className='TableButtons'>
                        <a
                          href={river.Url || "https://www.inatur.no/sok/laksefisketilbud"}
                          target='__blank'
                          className='btn btn-secondary '
                        >
                          FISKEKORT
                        </a>
                        <Link
                          to={`/bors/${river.ID}`}
                          className='btn btn-secondary'
                        >
                          LAKSEBØRS
                        </Link>
                      </td>
                    </tr>
                  );
                });
              }}
            </Query>
          </tbody>
        </table>
        <div className='paginateButtons'>
          {offset >= limit ? (
            <div className='col leftPageButton'>
              <button onClick={paginateLeft}>FORRIGE SIDE</button>
            </div>
          ) : null}

          {dataLength >= limit ? (
            <div className='col rightPageButton'>
              <button onClick={paginateRight}>NESTE SIDE</button>
            </div>
          ) : null}
        </div>
      </MediaQuery>

      <MediaQuery maxWidth={1224}>
        <table className=' table table-hover'>
          <tbody>
            <Query query={FETCH_RIVERS} fetchPolicy='no-cache' offset={offset}>
              {({ loading, error, data }) => {
                if (loading)
                  return (
                    <tr className='loadingTable'>
                      <td>Loading...</td>
                    </tr>
                  );
                if (error) console.log(error);

                if (data == undefined || data == null) {
                  return (
                    <tr key="0" className='loadingTable'>
                      <td>Kunne ikke hente data... Prøv å laste inn siden på nytt</td>
                    </tr>
                  );
                }

                setDataLength(data.rivers.length);

                return data.rivers.map((river) => {
                  return (
                    <tr key={river.ID}>
                      <td>
                        <div className='row riverTableHeading'>
                          {river.NAVN} <br></br>
                          <hr></hr>
                        </div>
                        <div className='row'>
                          <div className='leftButton'>
                            <a
                              href={river.Url || "https://www.inatur.no/sok/laksefisketilbud"}
                              target='__blank'
                              className='btn btn-secondary '
                            >
                              FISKEKORT
                            </a>
                          </div>
                          <div className='rightButton'>
                            <Link
                              to={`/bors/${river.ID}`}
                              className='btn btn-secondary'
                            >
                              LAKSEBØRS
                            </Link>
                          </div>
                          <div className='clearBoth'></div>
                        </div>
                      </td>
                    </tr>
                  );
                });
              }}
            </Query>
          </tbody>
        </table>
        <div className='paginateButtons'>
          {offset >= limit ? (
            <div className='col leftPageButton'>
              <button onClick={paginateLeft}>FORRIGE SIDE</button>
            </div>
          ) : null}

          {dataLength >= limit ? (
            <div className='col rightPageButton'>
              <button onClick={paginateRight}>NESTE SIDE</button>
            </div>
          ) : null}
        </div>
      </MediaQuery>
    </Fragment>
  );
};

export default RiverTable;
