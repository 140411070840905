import React, { Fragment } from "react";
import "./ImageCollageStatic.scss";
import Popup from "reactjs-popup";
import img1 from "../../images/collage/1.jpg";
import img2 from "../../images/collage/2.jpg";
import img3 from "../../images/collage/3.jpg";
import img4 from "../../images/collage/4.jpg";
import img5 from "../../images/collage/5.jpg";
import img6 from "../../images/collage/6.jpg";
import img7 from "../../images/collage/7.jpg";
import img8 from "../../images/collage/8.jpg";
import img9 from "../../images/collage/9.jpg";
import img10 from "../../images/collage/10.jpg";

const ImageCollage = () => {
  return (
    <div className='container ImageGallery'>
      <h2 className=' borderSetting '>UTVALGTE BILDER FRA LAKSEBØRSEN</h2>
      <div className='row imageCollection'>
        <div key='img1' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img1} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img1} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
        <div key='img2' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img2} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img2} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
        <div key='img3' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img3} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img3} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
        <div key='img4' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img4} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img4} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
        <div key='img5' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img5} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img5} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
      </div>
      <div className='row imageCollection'>
        <div key='im6' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img6} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img6} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
        <div key='img7' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img7} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img7} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
        <div key='img8' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img8} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img8} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
        <div key='img9' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img9} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img9} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
        <div key='img10' className='col-2.5 m-2 cursorPointer '>
          <Popup
            modal
            nested
            trigger={<img src={img10} alt=""></img>}
            position='center'
          >
            {(close) => (
              <Fragment>
                <p className='close' onClick={close}>
                  <div className='xClose'>x</div>
                  <div className='textClose'>LUKK</div>
                </p>
                <img src={img10} alt=""></img>
              </Fragment>
            )}
          </Popup>
        </div>
      </div>
    </div>
  );
};

export default ImageCollage;
