import gql from "graphql-tag";
import {QUERY_TYPES} from "../components/GraphView/GraphView";

export function WeeksQuery(riverId, zoneId, year) {
  const zoneQueryPart = zoneId && zoneId !== "0" ? `zoneId: ${zoneId}` : "";
  return gql`
  query WeeksQuery {
    weeks(
      riverId: ${riverId}
      year: "${year}"
      ${zoneQueryPart}
    ) {
      UKE
    }
  }
`;
}

export function CatchQuery(
  riverId,
  soneId,
  limit,
  offset,
  year,
  name,
  sort,
  week,
  weekSearch,
  filters,
) {
  const zoneQueryPart = soneId && soneId !== "0" ? `zoneId: ${soneId}` : "";
  const nameQueryPart = name ? `nameSearch: "${name}"` : "";
  const weekQueryPart = weekSearch ? `week: ${week}` : "";
  const filteredSpecies = (filters && filters.species) ? Object.keys(filters.species).filter(x => filters.species[x]) : [];
  const speciesQueryPart = filteredSpecies.length ? `speciesFilter: ${JSON.stringify(filteredSpecies)}` : "";
  const filteredTools = (filters && filters.tools) ? Object.keys(filters.tools).filter(x => filters.tools[x]) : [];
  const toolsQueryPart = filteredTools.length ? `toolsFilter: ${JSON.stringify(filteredTools)}` : "";
  const releasedQueryPart = (filters.properties && typeof filters.properties.released == "boolean") ? `releasedFilter: ${filters.properties.released}` : "";

  return gql`
    query CatchesQuery {
      catches (
        riverId: ${riverId}
        year: "${year}"
        ${weekQueryPart}
        ${zoneQueryPart}
        ${nameQueryPart}
        ${speciesQueryPart}
        ${toolsQueryPart}
        ${releasedQueryPart}
        limit: ${limit}
        offset: ${offset}
        sort: "${sort}")
      {
        DATO
        ID
        NAVN
        KG
        KortNummer
        SONE
        Redskap
        ART
        Bilde
        Bildefil
        Oppdrett
        Put_Take
      }
    }`;
}



export function LatestCatchesQuery() {
  return gql`
    query CatchesQuery {
      latestCatches {
        Bildefil
        DATO
        ART
        KG
        NAVN
        ElvNavn
        SONE
        Bilde
        Oppdrett
        Put_Take
        Redskap
      }
    }
  `;
}

export function graphQuery(riverId, graphNumber, year, mor, soneId) {
  if (mor) {
    return motherGraphQuery(riverId, graphNumber, year, soneId);
  } else {
    return childGraphQuery(riverId, graphNumber, year, soneId);
  }
}

// 3: Art comparison laks, sjøørret, sjørøye
function motherGraphQuery(riverId, graphNumber, year, soneId) {
    const zoneQueryPart = soneId && soneId !== "0" ? `zoneId: ${soneId}` : "";

    switch (graphNumber) {
    case 0:
    case QUERY_TYPES.graphAggregatedWeeksSeaTroutWholeRiver:
    case 10:
    case QUERY_TYPES.graphAggregatedWeeksSeaTroutZone:
      return gql`
        {
          aggregatedSpeciesPerWeekPerYearStatistics (
            riverId: ${riverId},
            ${zoneQueryPart},
            years: ["${year-2}", "${year-1}", "${year}"]
          ) {
            Uke
            Aar
            Art
            ant
          }
        }
      `;
    case 3:
    case 13:
      return gql`
        {
          aggregatedSpeciesSumsPerYearStatistics (
              riverId:${riverId},
              ${zoneQueryPart}
              years:["${year-2}", "${year-1}", "${year}"]
            ) {
            Laks
            SjoRoye
            SjoOrret
            YEAR
          }
        }
      `;
    case 6:
    case 16:
      return gql`
        {
          aggregatedCatchReleaseSumsPerYearStatistics (
              riverId:${riverId},
              ${zoneQueryPart}
              years:["${year-2}", "${year-1}", "${year}"]
            ) {
              Release
              Catch
              YEAR
          }
        }
      `;
    case 7:
    case 17:
      return gql`
        {
          aggregatedToolsPerYearStatistics (
              riverId:${riverId},
              ${zoneQueryPart}
              years:["${year-2}", "${year-1}", "${year}"]
            ) {
              year
              toolName
              amount
          }
        }
      `;
    default:
      return ``;
  }
}

// 3: Art comparison laks, sjøørret, sjørøye
export function childGraphQuery(riverId, graphNumber, year, soneId) {
  const zoneQueryPart = soneId && soneId !== "0" ? `zoneId: ${soneId}` : "";

  switch (graphNumber) {
    case 0:
    case 10:
    case QUERY_TYPES.graphAggregatedWeeksSeaTroutWholeRiver:
    case QUERY_TYPES.graphAggregatedWeeksSeaTroutZone:
      return gql`
        {
          aggregatedSpeciesPerWeekPerYearStatistics (
            riverId: ${riverId},
            ${zoneQueryPart},
            years: ["${year-2}", "${year-1}", "${year}"]
          ) {
            Uke
            Aar
            Art
            ant
          }
        }
      `;
    case 3:
    case 13:
      return gql`
        {
          aggregatedSpeciesSumsPerYearStatistics (
              riverId:${riverId},
              ${zoneQueryPart}
              years:["${year-2}", "${year-1}", "${year}"]
            ) {
            Laks
            SjoRoye
            SjoOrret
            YEAR
          }
        }
      `;
    case 6:
    case 16:
      return gql`
      {
        aggregatedCatchReleaseSumsPerYearStatistics (
            riverId:${riverId},
            ${zoneQueryPart}
            years:["${year-2}", "${year-1}", "${year}"]
          ) {
            Release
            Catch
            YEAR
        }
      }
    `;
    case 7:
    case 17:
      return gql`
        {
          aggregatedToolsPerYearStatistics (
              riverId:${riverId},
              ${zoneQueryPart}
              years:["${year-2}", "${year-1}", "${year}"]
            ) {
              year
              toolName
              amount
          }
        }
      `;
    default:
      return ``;
  }
}

export function aggregatedSalmonCatchesPerWeekQuery(riverId, year, mor, zoneId) {
    return gql`
        query CatchesQuery {
          aggregatedSpeciesPerWeekPerYearStatistics(
            ${riverId !== "0" ? "riverId : " + riverId : ""}
            ${zoneId !== "0" ? "zoneId : " + zoneId : ""}
            years: ["${year}"],
            speciesFilter: ["Laks"]
          ) {
            kg,
            ant,
            Art,
            Uke
          }
        }
      `;
}

export function aggregatedCatchQueries(riverId, zoneId, year, species) {
  const zoneQueryPart = zoneId && zoneId !== "0" ? `zoneId: ${zoneId}` : "";
  return gql`
    {
      aggregatedWeightAndNumbersPerWeekStatistics(
        riverId: ${riverId},
        ${zoneQueryPart}
        year: ${year},
        speciesCodes: ${JSON.stringify(species)}
      ) {
        Aar
        Uke
        KG
        Kg03
        Kg37
        kg7
        antkg03
        antkg37
        antkg7
        ant
        Maks
        Fangstkode
      }
    }
  `;
}

export function toolAvailabilityQueries(riverId, zoneId, year) {
  const zoneQueryPart = zoneId && zoneId !== "0" ? `zoneId: ${zoneId}` : "";
  return gql`
    {
      toolsUsed(
        riverId: ${riverId},
        ${zoneQueryPart}
        year:"${year}"
      ) {
        toolName
      }
    }
  `;
}

export function initialStockPageQueries(riverId) {
  return gql`
    query RiverInfoQuery {
      riverInfo(
        riverId: ${riverId}
      )
      {
        ID
        NAVN
        ELVNAVN
        Mor
        fylkeID
        Url
      }
    }
  `
}

export function getRedirectUrl(riverId) {
    return gql`
    query RedirectUrlQuery {
      redirectUrl(
        riverId: ${riverId}
      )
      {
        url
      }
    }
  `
}
