import React from "react";
import moment from "moment";
import "./CatchReport.scss";

import MediaQuery from "react-responsive";
import { Fragment } from "react";
const CatchReport = ({ Catch }) => {
  return (
    <Fragment>
        <div className='CatchReport borderSettingBig'>
          {Catch.Bildefil && (
            <div className='imageFrame'>
              <img src={Catch.Bildefil} alt='Fangstbilde'></img>
            </div>
          )}
          <div className='CatchReportDataWrapper borderSetting'>
            <div className='even'>
              <span className='CatchCol'>Dato</span>
              <span className='CatchData'>
                {moment.unix(Catch.DATO / 1000).format("DD.MM.YYYY")}
              </span>
            </div>
            <div className='odd'>
              <span className='CatchCol'>Vekt</span>
              <span className='CatchData'>{Catch.KG.toFixed(1)}</span>
            </div>
            <div className='even'>
              <span className='CatchCol'>Art</span>
              <span className='CatchData'>Laks</span>
            </div>
            <div className='odd'>
              <span className='CatchCol'>Redskap</span>
              <span className='CatchData'>{Catch.Redskap}</span>
            </div>
            <div className='even'>
              <span className='CatchCol'>Elv</span>
              <span className='CatchData'>{Catch.ElvNavn}</span>
            </div>
            <div className='even'>
              <span className='CatchCol'>Vald/Sone</span>
              <span className='CatchData'>{Catch.SONE}</span>
            </div>
            <div className='odd'>
              <span className='CatchCol'>Fisker</span>
              <span className='CatchData'>{Catch.NAVN}</span>
            </div>
            <div className='even'>
              <span className='CatchCol'>Satt ut</span>
              <span className='CatchData'>
                {Catch.Put_Take === 1 ? "JA" : "NEI"}
              </span>
            </div>
            <div className='odd'>
              <span className='CatchCol'>Oppdrett</span>
              <span className='CatchData'>
                {Catch.Oppdrett === "J" ? "JA" : "NEI"}
              </span>
            </div>
          </div>
        </div>
    </Fragment>
  );
};

export default CatchReport;
