import "./App.scss";
import "./css/bootstrap.min.css";
import { React, useEffect } from "react";
import ReactGA from "react-ga4";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { BrowserRouter as Router, Route } from "react-router-dom";
import LandingPage from "./components/Pages/LandingPage";
import StockPage from "./components/Pages/StockPage";
const { REACT_APP_GRAPHQL_HOST } = process.env;

const TRACKING_ID = "G-QSMM6KDMVN";
ReactGA.initialize(TRACKING_ID);

const cache = new InMemoryCache();
const graphqlHostUri = REACT_APP_GRAPHQL_HOST ?? "https://laksebors.inatur.no";
const graphQLClient = new ApolloClient({
  uri: `${graphqlHostUri}/graphql`,
  cache: cache
});

function App() {
  useEffect(() => {
    ReactGA.send('pageview');
  }, []);

  return (
    <ApolloProvider client={graphQLClient}>
      <Router>
        <div className='d-flex flex-column h-100'>
          <Route exact path='/' component={LandingPage}></Route>
          <Route
            exact
            path='/bors/:riverId'
            component={StockPage}
          ></Route>
        </div>
      </Router>
    </ApolloProvider>
  );
}

export default App;
