import React, { useEffect, useRef, useState } from "react";
import { useQuery } from "@apollo/client";
import MediaQuery from "react-responsive";
import Popup from "reactjs-popup";
import search from "../../images/searchWhite.svg";
import Contracter from "../../images/snipgreen.png";
import Expander from "../../images/snipgreenflipped.png";
import Unsorted from "../../images/unsorted.png";
import { WeeksQuery } from "../../utils/QueryGenerator";
import { SortableHeaders } from "../StockTable/SortableHeaders";
import { TableFilters } from "../TableFilters/TableFilters";
import { TableFiltersButton } from "../TableFilters/TableFiltersButton";

export const StockTableWeekHeading = ({TableHeading, NameSearch, riverId, soneId, year, isLoading, goToWeek, changeSorting, week, name, filters, changeFilters}) => {
  const [dataList, setDataList] = useState([]);
  const [nameInput, setNameInput] = useState("");
  const [sorting, setSorting] = useState({sortBy: "DATE", direction: "ASC"});
  const didMount = useRef(false); // prevent useeffect on page load

  const FETCH_WEEKS = WeeksQuery(riverId, soneId, year);

  const {loading, error, data} = useQuery(FETCH_WEEKS, {
    fetchPolicy: "no-cache",
    skip: riverId === "0"
  });
  if (error) console.log(error);

  function nextSortDirection(sortDirection) {
    if (sortDirection == "ASC") return "DESC";
    if (sortDirection == "DESC") return "ASC";
  }

  function switchSorting(column) {
    if (sorting.sortBy == column) {
      const newSorting = {...sorting, direction: nextSortDirection(sorting.direction)};
      setSorting(newSorting);
      changeSorting(newSorting.sortBy, newSorting.direction === "ASC" ? "" : "-");
    } else {
      const newSorting = {sortBy: column, direction: "ASC"};
      setSorting(newSorting);
      changeSorting(newSorting.sortBy, newSorting.direction === "ASC" ? "" : "-");
    }
  }

  function renderCorrectSortArrow(column) {
    if (sorting.sortBy === column && sorting.direction === "ASC") {
      return (<img src={Contracter} alt="Descending order"></img>);
    } else if (sorting.sortBy === column && sorting.direction === "DESC") {
      return (<img src={Expander} alt="Ascending order"></img>);
    } else {
      return (
        <img src={Unsorted} alt="Sort by this column"></img>
      );
    }
  }

  useEffect(() => {
    if (data) { setDataList(data.weeks); }
  }, [data]);

  const changeWeek = (e) => {
    goToWeek(e.target.value, year);
  };

  function onNameInputChange(e) {
    setNameInput(e.target.value);
    NameSearch(e);
  }

  useEffect(() => {
    if (name === "" && nameInput.length > 3) {
      setNameInput("");
    }
  }, [name]);

  return (
    <>
      <MediaQuery minWidth={1224}>
        <thead className='StockTableHeaderWrapper'>
          <tr>
            <th className='firstTH' colSpan='1'>
              {TableHeading}{" "}
            </th>
            <th colSpan='4'>
              {"Uke: "}
              {loading && (
                <span>Loading...</span>
              )}
              {(data === undefined || data === null) && (
                <span>Kunne ikke hente data... Prøv å laste inn siden på nytt</span>
              )}
              {dataList && !isLoading && (
                <select onChange={changeWeek} value={week}>
                  <option value='Alle' key={0}>
                    Alle uker
                  </option>
                  {dataList.filter((week, index) => index === 0 || dataList[index - 1].UKE !== dataList[index].UKE).map((week, index) =>
                    <option value={week.UKE} key={week.UKE}>
                      {week.UKE}
                    </option>
                  )}
                </select>
              )}
            </th>

            <th colSpan='2'>
              <div className='form-inline my-2 my-lg-0 NameSearch'>
                <input
                  className='form-control mr-sm-2'
                  type='text'
                  placeholder='NAVNESØK'
                  onChange={onNameInputChange}
                  value={nameInput}
                  style={{
                    backgroundImage: `url(${search}`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "right",
                  }}
                />
              </div>
            </th>

            <th colSpan='2'>
              <Popup
                trigger={<TableFiltersButton />}
                modal
                nested
                position='center'
              >
                {(close) =>
                  <TableFilters
                    close={close}
                    filters={filters}
                    filtersChanged={changeFilters}
                    riverId={riverId}
                    zoneId={soneId}
                    year={year}
                  />
                }
              </Popup>
            </th>
          </tr>
        </thead>

        <SortableHeaders
          renderCorrectSortArrow={renderCorrectSortArrow}
          switchSorting={switchSorting}
        />
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <thead className='StockTableHeaderWrapperMobile StockTableHeaderWrapper'>
          <tr>
            <th className='firstTH' colSpan='9'>
              <div className='mobileTableHeader'>
                {TableHeading}
              </div>

              <div className='mobileTableHeader' style={{"marginTop": "15px"}}>
                {"Uke: "}

                {loading && (
                  <span>Loading...</span>
                )}

                {(data === undefined || data === null) && (
                  <span>Kunne ikke hente data... Prøv å laste inn siden på nytt</span>
                )}

                {dataList && !isLoading && (
                  <select onChange={changeWeek} value={week}>
                    <option value='Alle' key={0}>Alle uker</option>
                    {dataList.filter((week, index) => index === 0 || dataList[index - 1].UKE !== dataList[index].UKE).map((week, index) =>
                      <option value={week.UKE} key={week.UKE}>
                        {week.UKE}
                      </option>
                    )}
                  </select>
                )}
              </div>

              <div className='mobileTableHeader'>
                <div className='form-inline NameSearch'>
                  <input
                    className='form-control mr-sm-2'
                    type='text'
                    placeholder='NAVNESØK'
                    onChange={onNameInputChange}
                    value={nameInput}
                    style={{
                      backgroundImage: `url(${search}`,
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "right",
                    }}
                  />
                </div>
              </div>
              <div className='mobileTableHeader'>
                <Popup
                  trigger={<TableFiltersButton className="mobile"/>}
                  modal
                  nested
                  position='center'
                >
                  {(close) =>
                    <TableFilters
                      close={close}
                      filters={filters}
                      filtersChanged={changeFilters}
                      riverId={riverId}
                      zoneId={soneId}
                      year={year}
                    />
                  }
                </Popup>
              </div>
            </th>
          </tr>
        </thead>

        <SortableHeaders
          renderCorrectSortArrow={renderCorrectSortArrow}
          switchSorting={switchSorting}
        />
      </MediaQuery>
    </>
  )
}