import React from "react";
import { tableLoader } from "../../utils/general";
import moment from "moment";
import Camera from "../../images/camera.svg";
import Release from "../../images/sattut.svg";
import Breeding from "../../images/oppdrett.svg";
import Popup from "reactjs-popup";

export const StockTableBody = ({ dataList, loading, error }) => {
  if (error) console.log(error);

  return (
    <tbody>
    {(dataList === undefined || dataList === null) && (
      <tr key="0">
        <td></td>
      </tr>
    )}
    {loading ? (
      tableLoader(20, 8)
    ) : (
        (dataList !== undefined && dataList != null) && (
      dataList.map((CATCH, index) => {
        return (
          <tr key={"catch_" + index}>
            <td>
              {moment
                .unix(CATCH.DATO / 1000)
                .format("DD.MM.YYYY")}
            </td>
            <td>{CATCH.KG.toFixed(1)}</td>
            <td>{CATCH.ART}</td>
            <td>{CATCH.Redskap}</td>
            <td>{CATCH.SONE}</td>
            <td>{CATCH.NAVN}</td>

            <td>
              {CATCH.Bilde === "J" ? (
                <Popup
                  trigger={<img src={Camera} alt=""></img>}
                  modal
                  nested
                  position='center'
                >
                  {(close) => (
                    <>
                      <p className='close' onClick={close}>
                        <div className='xClose'>x</div>
                        <div className='textClose'>LUKK</div>
                      </p>
                      <img src={CATCH.Bildefil} alt=""></img>
                    </>
                  )}
                </Popup>
              ) : (
                <span className='ImageWidth'></span>
              )}
            </td>
            <td>
              {CATCH.Put_Take === 1 ? (
                <img src={Release} alt=""></img>
              ) : null}
            </td>
            <td>
              {CATCH.Oppdrett === "J" ? (
                <img src={Breeding} alt=""></img>
              ) : null}
            </td>
          </tr>
        )
      })
        ))}
    </tbody>
  );
}