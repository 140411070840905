import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";
export const RiverList = ({onChangeRiver, SelectedRiverId, CountyId}) => {
  // Apollo query
  const FETCH_RIVERS = gql`
  query RiversQuery {
    rivers(
      countyId: "${CountyId}"
    )
    {
      ID
      NAVN
      ELVNAVN
      Mor
      fylkeID
      Url
    }
  }
  `;
  const [rivers, setRivers] = useState([]);
  const {data, isLoading, error} = useQuery(FETCH_RIVERS, { skip: CountyId === "0" });
  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if(data?.rivers) {
      setRivers(data.rivers);
    }
  }, [data])

  function onChange(e) {

    const selectedRiverId = e.target.value;
    if (selectedRiverId !== "0") {
      const selectedRiver = data?.rivers.find(river => river.ID === selectedRiverId);
      onChangeRiver({ riverId: selectedRiver.ID, isMother: selectedRiver.Mor === 1, countyId: String(selectedRiver.fylkeID), url: selectedRiver.Url});
    }
  }

  return (
    <>
      {isLoading && (
        <h4>Loading...</h4>
      )}
      {
        <div className='input-group '>
          <select
            className='custom-select borderSetting'
            id='SelectRiver'
            value={parseInt(SelectedRiverId)}
            onChange={onChange}
          >
            {data ? (
              <option value={"0"}>VELG ELV </option>
            ) : (
              <option disabled>VELG FYLKE FØRST</option>
            )}
            { rivers.map((river) => {
                return (
                  <option key={river.ID} value={river.ID}>
                    {river.NAVN.toUpperCase()}
                  </option>
                );
              })}
          </select>
        </div>
      }
    </>
  );
};
