import React, {Fragment, useEffect, useState} from "react";
import Chart from "./Chart";
import "./GraphView.scss";
import classNames from "classnames";
import LoadingOverlay from "react-loading-overlay-nextgen";
import MediaQuery from "react-responsive";
import {makeChartData} from "../../utils/ChartGenerator";
import {useQuery} from "@apollo/client";
import {graphQuery} from "../../utils/QueryGenerator";

const defaultChartData = { datasets: [], labels: []}

const GraphView = ({
  heading,
  ChangeGraphView,
  queryNumber,
  year,
  soneId,
  mor,
  riverId
}) => {
  const [GraphMenuShow, setGraphMenuShow] = useState(false);
  const [GraphNumber, setGraphNumber] = useState(queryNumber);
  const [graphLabels, setGraphLabels] = useState([year-2, year-1, year]);
  const [chartData, setChartData] = useState({...defaultChartData});

  const { data, loading, error } = useQuery(graphQuery(riverId, queryNumber, year, mor, soneId), {
    fetchPolicy:  "no-cache",
    skip: riverId === "0"
  });

  useEffect(() => {
    if (error) {
      console.error(error);
    }

    if (data !== undefined && riverId !== "0") {
      const queryResult = extractCorrectQueryData(data);
      setChartData(makeChartData(queryResult, queryNumber, mor, graphLabels));
    } else {
      setChartData({...defaultChartData});
    }
  }, [data, queryNumber, mor, graphLabels, riverId]);

  useEffect(() => {
    setGraphLabels([year-2, year-1, year]);
  }, [year])

  const GraphMenu = () => {
    setGraphMenuShow(!GraphMenuShow);
  };

  const ChangeGraph = (type) => {
    let graphNumber = 0;
    switch (type.target.accessKey) {
      case "ant":
        graphNumber = soneId !== "0" ? 10 : 0;
        ChangeGraphView(
          graphNumber,
          "fangstuke",
          "ANTALL LAKS PER UKE SISTE 3 ÅR"
        );
        setGraphNumber(graphNumber);
        break;

      case "ant_sea_trout":
        graphNumber = soneId !== "0" ? QUERY_TYPES.graphAggregatedWeeksSeaTroutZone : QUERY_TYPES.graphAggregatedWeeksSeaTroutWholeRiver;
        ChangeGraphView(
          graphNumber,
          "fangstuke_salmon",
          "ANTALL SJØØRRET PER UKE SISTE 3 ÅR"
        );
        setGraphNumber(graphNumber);
        break;
      // case "kg":
      //   ChangeGraphView(1, "fangstuke", "ANTALL KILO PER UKE");
      //   setGraphNumber(1);
      //   break;
      // case "antkg":
      //   ChangeGraphView(2, "fangstuke", "ANTALL OG KILO PER UKE");
      //   setGraphNumber(2);
      //   break;

      case "artCompare":
        graphNumber = soneId !== "0" ? 13 : 3;
        ChangeGraphView(graphNumber, "artcomparison", "ART ANTALL SISTE 3 ÅR");
        setGraphNumber(graphNumber);
        break;

      case "cr":
        graphNumber = soneId !== "0" ? 16 : 6;
        ChangeGraphView(graphNumber, "cr", "C/R LAKS ANTALL SISTE 3 ÅR");
        setGraphNumber(graphNumber);
        break;
      case "tool":
        graphNumber = soneId !== "0" ? 17 : 7;
        ChangeGraphView(graphNumber, "tool", `TOTAL FANGST LAKS PR. REDSKAP`);
        setGraphNumber(graphNumber);
        break;

      default:
        break;
    }
  };

  return (
    <Fragment>
      <LoadingOverlay
        active={loading}
        spinner
        text='Laster graf'
        className='loadingOverlay'
      >
      <div className='borderSetting heading GraphHeader'>
        <span className='GraphHeading'>{heading + " - " + year}</span>
        <MediaQuery minWidth={1224}>
          <div
            className={classNames({
              graphChooserWrapper: true,
              graphChooserWrapperOpen: GraphMenuShow,
              borderSetting: GraphMenuShow,
            })}
          >
            <span
              className={classNames({
                GraphChooser: !GraphMenuShow,
                GraphChooserLight: GraphMenuShow,
                // borderSetting: GraphMenuShow,
              })}
              onClick={GraphMenu}
            >
              <div className='openGraphMenuWrapper'>
                <span className='dotMenu'>
                  <div className='container'>
                    <div className='row'></div>
                    <div className='row'></div>
                    <div className='row'></div>
                  </div>
                </span>

                <div className='graphText'> VELG GRAF</div>
              </div>
              {/* <div className='clearBoth'></div> */}
              {GraphMenuShow && (
                <div className='GraphMenu'>
                  <div accessKey='ant' onClick={ChangeGraph}>
                    ANTALL LAKS PER UKE SISTE 3 ÅR
                  </div>
                  <div accessKey='ant_sea_trout' onClick={ChangeGraph}>
                    ANTALL SJØØRRET PER UKE SISTE 3 ÅR
                  </div>
                  {/* <div accessKey='kg' onClick={ChangeGraph}>
                    ANTALL KILO PER UKE
                  </div> */}
                  {/* <div accessKey='antkg' onClick={ChangeGraph}>
                    ANTALL OG KILO PER UKE
                  </div> */}
                  <div accessKey='tool' onClick={ChangeGraph}>
                    TOTAL FANGST LAKS PR. REDSKAP
                  </div>
                  <div accessKey='cr' onClick={ChangeGraph}>
                    C/R LAKS ANTALL SISTE 3 ÅR
                  </div>
                  <div accessKey='artCompare' onClick={ChangeGraph}>
                    ART ANTALL SISTE 3 ÅR
                  </div>
                </div>
              )}
            </span>
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <span
            className={classNames({
              GraphChooserMobile: true,
              borderSetting: GraphMenuShow,
            })}
            onClick={GraphMenu}
          >
            <span className='dotMenu'>
              <div className='container'>
                <div className='row'></div>
                <div className='row'></div>
                <div className='row'></div>
              </div>
            </span>
            {GraphMenuShow && (
              <div className='GraphMenu borderSetting'>
                <div accessKey='ant' onClick={ChangeGraph}>
                  ANTALL LAKS PER UKE SISTE 3 ÅR
                </div>
                <div accessKey='ant_sea_trout' onClick={ChangeGraph}>
                  ANTALL SJØØRRET PER UKE SISTE 3 ÅR
                </div>
                {/* <div accessKey='kg' onClick={ChangeGraph}>
                  ANTALL KILO PER UKE
                </div> */}
                {/* <div accessKey='antkg' onClick={ChangeGraph}>
                  ANTALL OG KILO PER UKE
                </div> */}
                <div accessKey='tool' onClick={ChangeGraph}>
                  TOTAL FANGST LAKS PR. REDSKAP
                </div>
                <div accessKey='cr' onClick={ChangeGraph}>
                  C/R LAKS ANTALL SISTE 3 ÅR
                </div>
                <div accessKey='artCompare' onClick={ChangeGraph}>
                  ART ANTALL SISTE 3 ÅR
                </div>
              </div>
            )}
          </span>
        </MediaQuery>
      </div>
      <Chart
        data={chartData}
        labels={graphLabels}
      ></Chart>
      </LoadingOverlay>
    </Fragment>
  );
};

function extractCorrectQueryData(data) {
  const queriesWithResult = Object.keys(data);
  if (queriesWithResult.length !== 1) {
    console.error(`Got unexpected number of results for graph query. Expected 1, got ${queriesWithResult?.length}`);
    return null;
  }
  return data[queriesWithResult[0]];
}

const QUERY_TYPES = {
  graphAggregatedWeeksSeaTroutWholeRiver: "GRAPH_AGGREGATED_WEEK_SEA_TROUT_WHOLE_RIVER",
  graphAggregatedWeeksSeaTroutZone: "GRAPH_AGGREGATED_WEEK_SEA_TROUT_ZONE"
}

export default GraphView;
export { QUERY_TYPES }
