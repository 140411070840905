import React from "react";

export const PaginationButtons = ({ pageLimit, offset, resultSize, onPaginateLeft, onPaginateRight}) => {
  return (
    <div className='paginateButtons'>
    {offset > 0 ? (
      <div className='col leftPageButton'>
        <button onClick={onPaginateLeft}>FORRIGE SIDE</button>
      </div>
    ) : null}
    {resultSize === pageLimit ? (
      <div className='col rightPageButton'>
        <button onClick={onPaginateRight}>NESTE SIDE</button>
      </div>
    ) : null}
  </div>
  );
}