import React, { useEffect, useState } from "react";
import { ReactComponent as Map } from "../../images/norway.svg";
import "./MapSelector.scss";
import classNames from "classnames";

export const MapSelector = ({ OnClick }) => {
  const [CountyName, setCountyName] = useState("Velg fylke");
  const [hovering, setHovering] = useState(false);
  const [SelectedCountyName, setSelectedCountyName] = useState("Velg fylke");

  useEffect(() => {
    setCountyName(SelectedCountyName);
  }, [SelectedCountyName]);

  const mouseOutEffect = (e) => {
    e.preventDefault();
    setHovering(false);

    setCountyName(SelectedCountyName);
  };

  const mouseOverEffect = (e) => {
    setHovering(true);
    e.preventDefault();
    setCountyName(e.target.id);
  };
  const SelectCounty = (e) => {
    e.preventDefault();

    setCountyName(e.target.id);
    const county = document.getElementById(e.target.id).parentNode;
    var oldCounty = document.getElementsByClassName("clicked");
    if (oldCounty.length > 0) oldCounty[0].classList.remove("clicked");
    county.classList.add("clicked");
    setSelectedCountyName(e.target.id);
  };

  const GoSearch = () => {
    OnClick(SelectedCountyName);
  };

  useEffect(() => {
    var counties = document.getElementsByClassName("MapCounty");

    for (var i = 0; i < counties.length; i++) {
      counties[i].addEventListener("mouseover", mouseOverEffect);
      counties[i].addEventListener("mouseout", mouseOutEffect);
      counties[i].addEventListener("click", SelectCounty);
    }
  });

  return (
    <div className='MapSelectorWrapper'>
      <div className='BorderWrapper'>
        <h4>VELG FYLKE I KART</h4>
        <Map className='map'></Map>
        <div
          className={classNames({
            "CountyInfo Visible": hovering,
            "CountyInfo  Hidden": !hovering,
          })}
        >
          <span> {CountyName}</span>
        </div>
        <div
          className={classNames({
            "CountyInfo CountyLift Visible": !hovering,
            "CountyInfo CountyLift Hidden": hovering,
          })}
        >
          {SelectedCountyName}
        </div>
      </div>
      <button
        className='ButtonGreen pl-4 pr-4 py-1 mt-3'
        onClick={() => GoSearch({ SelectedCountyName })}
      >
        SE TREFF
      </button>
      {/* <div>{SelectedCountyName}</div> */}
    </div>
  );
};
