import React, { Fragment } from "react";
import ToppBanner from "../images/laksebannercropped.jpg";
import ToppBannerMobile from "../images/laksebannermobile.png";
import Logo from "../images/inatur-logo.svg";
import classNames from "classnames";
import MediaQuery from "react-responsive";

const Header = ({ FrontPage }) => {
  return (
    <header
      className={classNames({
        FrontPage: FrontPage,
        SubPage: !FrontPage,
      })}
    >
      <div className='top mb-2'>
        <div className='logo'>
          <a href='/'>
            <img src={Logo} alt='Logo home button'></img>
            <MediaQuery minWidth={1200}>
              <span className='logotext'>Laksebørs</span>
            </MediaQuery>
          </a>
        </div>
        <div className='top-bar__register-catch-button'>
          <a
            target='__blank'
            href='https://www.inatur.no/fangstrapportering'
          >
            <button className='borderSetting'>
              RAPPORTER FANGST
            </button>
          </a>
        </div>
      </div>
      {FrontPage && (
        <Fragment>
          <div className='bottom'>
            <MediaQuery minWidth={700}>
              <img src={ToppBanner} alt='Topp banner'></img>
            </MediaQuery>
            <MediaQuery maxWidth={700}>
              <img src={ToppBannerMobile} alt='Topp banner mobile'></img>
            </MediaQuery>
          </div>
        </Fragment>
      )}
    </header>
  );
};

export default Header;
