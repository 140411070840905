import React, {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom'
import {useQuery} from "@apollo/client";
import {initialStockPageQueries, getRedirectUrl} from "../../utils/QueryGenerator";

export default function UrlStateHandler({riverId, onChangeRiver}) {
  const location = useLocation();
  const history = useHistory();
  const [isInitialPageLoad, setIsInitialPageLoad] = useState(true);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  const {data, error} = useQuery(initialStockPageQueries(riverId), {
    skip: !isInitialPageLoad || riverId === "0"
  });

  const {data: redirectData, error: redirectError} = useQuery(getRedirectUrl(riverId), {
    skip: !shouldRedirect
  });


  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if (isInitialPageLoad && data?.riverInfo?.ID) {
      const river = {
        riverId: data?.riverInfo.ID,
        countyId: String(data?.riverInfo.fylkeID),
        isMother: data?.riverInfo.Mor === 1,
        url: data?.riverInfo.Url
      };
      setIsInitialPageLoad(false);
      onChangeRiver(river);
    } else if (isInitialPageLoad && data?.riverInfo?.ID === null) {
      setShouldRedirect(true);
    }
  }, [data])

  useEffect(() => {
    if (riverId !== undefined && riverId !== "0") {
      location.pathname = `/bors/${riverId}`;
      history.replace(location)
    }
  }, [riverId])

  useEffect(() => {
    if (redirectData) {
      window.location.assign(redirectData.redirectUrl.url);
    }
  }, [redirectData]);

  return (
    <></>
  );
}
