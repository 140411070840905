import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import LoadingOverlay from "react-loading-overlay-nextgen";
import MediaQuery from "react-responsive";
import Popup from "reactjs-popup";
import search from "../../images/searchWhite.svg";
import Contracter from "../../images/snipgreen.png";
import Expander from "../../images/snipgreenflipped.png";
import Unsorted from "../../images/unsorted.png";
import { CatchQuery } from "../../utils/QueryGenerator";
import { TableFilters } from "../TableFilters/TableFilters";
import { TableFiltersButton } from "../TableFilters/TableFiltersButton";
import { PaginationButtons } from "./PaginationButtons";
import { SortableHeaders } from "./SortableHeaders";
import "./StockTable.scss";
import { StockTableBody } from "./StockTableBody";

const StockTable = ({
  riverId,
  year,
  soneId,
  NameSearch,
  name,
  TableHeading,
  limit,
  offset,
  paginateLeft,
  paginateRight,
  changeSorting,
  sort,
  week,
  weekTable,
  filters,
  changeFilters,
}) => {
  const [nameInput, setNameInput] = useState("");
  const [currentTable, setCurrentTable] = useState(TableHeading);
  const [sorting, setSorting] = useState({sortBy: "DATE", direction: "ASC"});

  function nextSortDirection(sortDirection) {
    if (sortDirection == "ASC") return "DESC";
    if (sortDirection == "DESC") return "ASC";
  }

  function switchSorting(column) {
    if (sorting.sortBy == column) {
      const newSorting = {...sorting, direction: nextSortDirection(sorting.direction)};
      setSorting(newSorting);
      changeSorting(newSorting.sortBy, newSorting.direction === "ASC" ? "" : "-");
    } else {
      const newSorting = {sortBy: column, direction: "ASC"};
      setSorting(newSorting);
      changeSorting(newSorting.sortBy, newSorting.direction === "ASC" ? "" : "-");
    }
  }

  function renderCorrectSortArrow(column) {
    if (sorting.sortBy === column && sorting.direction === "ASC") {
      return (<img src={Contracter} alt="Descending order"></img>);
    } else if (sorting.sortBy === column && sorting.direction === "DESC") {
      return (<img src={Expander} alt="Ascending order"></img>);
    } else {
      return (
        <img src={Unsorted} alt="Sort by this column"></img>
      );
    }
  }

  // Apollo query
  const fetchQuery = CatchQuery(
    riverId,
    soneId,
    limit,
    offset,
    year,
    name,
    sort,
    week,
    weekTable,
    filters,
  );

  const [dataList, setDataList] = useState([]);
  const {loading, error, data} = useQuery(fetchQuery, {
    fetchPolicy: "no-cache",
    skip: riverId === "0"
  });
  useEffect(() => {
    if (data) {
      setDataList(loading ? [] : data.catches );
    }
  }, [data])

  useEffect(() => {
    if (riverId === "0") {
      setDataList([]);
    }
  }, [riverId])


  function onNameInputChange(e) {
    setNameInput(e.target.value);
    NameSearch(e);
  }

  // Hack because the same table is used, and switching between does not trigger component remount.
  useEffect(() => {
    if (TableHeading !== currentTable || (name === "" && nameInput.length > 3)) {
      setNameInput("");
      setCurrentTable(TableHeading);
    }
  }, [TableHeading, name]);

  return (
    <Fragment>
      <MediaQuery minWidth={1224}>
        <LoadingOverlay
          active={loading}
          spinner
          text='Laster inn fangst...'
          className='loadingOverlay'
        >
          <div className='StockTable '>
            <table className='table table-hover'>
              <thead className='StockTableHeaderWrapper'>
                <tr>
                  <th className='firstTH' colSpan='5'>
                    {TableHeading}
                  </th>

                  <th colSpan='2'>
                    <div className='form-inline my-2 my-lg-0 NameSearch'>
                      <input
                        className='form-control mr-sm-2'
                        type='text'
                        placeholder='NAVNESØK'
                        onChange={onNameInputChange}
                        value={nameInput}
                        style={{
                          backgroundImage: `url(${search}`,
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "right",
                        }}
                      />
                    </div>
                  </th>

                  <th colSpan='2'>
                    <Popup
                      trigger={<TableFiltersButton />}
                      modal
                      nested
                      position='center'
                    >
                      {(close) =>
                        <TableFilters
                          close={close}
                          filters={filters}
                          filtersChanged={changeFilters}
                          riverId={riverId}
                          zoneId={soneId}
                          year={year}
                        />
                      }
                    </Popup>
                  </th>
                </tr>
              </thead>

              <SortableHeaders
                renderCorrectSortArrow={renderCorrectSortArrow}
                switchSorting={switchSorting}
              />

              <StockTableBody
                dataList={dataList}
                loading={loading}
                error={error}
              />
            </table>
            <PaginationButtons
              offset={offset}
              resultSize={dataList?.length ?? 0}
              onPaginateLeft={paginateLeft}
              onPaginateRight={paginateRight}
              pageLimit={limit}
            />
          </div>
        </LoadingOverlay>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <LoadingOverlay
          active={loading}
          spinner
          text='Laster inn fangst...'
          className='loadingOverlay'
        >
          <div className='StockTable StockTableMobile '>
            <table className='table table-hover '>
              <thead className='StockTableHeaderWrapperMobile '>
                <tr>
                  <th className='firstTH' colSpan='9'>
                    <div className='mobileTableHeader'> {TableHeading}</div>
                    <div className='mobileTableHeader'>
                      <form className='form-inline NameSearch'>
                        <input
                          className='form-control mr-sm-2'
                          type='text'
                          placeholder='NAVNESØK'
                          onChange={onNameInputChange}
                          value={nameInput}
                          style={{
                            backgroundImage: `url(${search}`,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "right",
                          }}
                        />
                      </form>
                    </div>
                    <div className='mobileTableHeader'>
                      <Popup
                        trigger={<TableFiltersButton className="mobile"/>}
                        modal
                        nested
                        position='center'
                      >
                        {(close) =>
                          <TableFilters
                            close={close}
                            filters={filters}
                            filtersChanged={changeFilters}
                            riverId={riverId}
                            zoneId={soneId}
                            year={year}
                          />
                        }
                      </Popup>
                    </div>
                  </th>
                </tr>
              </thead>

              <SortableHeaders
                renderCorrectSortArrow={renderCorrectSortArrow}
                switchSorting={switchSorting}
              />

              <StockTableBody
                dataList={dataList}
                loading={loading}
                error={error}
              />
            </table>
            <PaginationButtons
              offset={offset}
              resultSize={dataList?.length ?? 0}
              onPaginateLeft={paginateLeft}
              onPaginateRight={paginateRight}
              pageLimit={limit}
              />
          </div>
        </LoadingOverlay>
      </MediaQuery>
    </Fragment>
  );
};

export default StockTable;
