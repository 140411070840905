import React from "react";
import {useQuery} from "@apollo/client";
import gql from "graphql-tag";

export const RiverSoneList = ({changeZone, selectedRiverId, selectedZoneId, selectedYear}) => {
  const FETCH_RIVERSONES = gql`
    query ZonesWithCatchesQuery {
      zonesWithCatch(riverId:${selectedRiverId},year:"${selectedYear}") {
        zoneId
        zoneName
      }
    }
  `;
  const {loading, error, data} = useQuery(FETCH_RIVERSONES, {
    variables: {selectedRiverId},
    skip: selectedRiverId === "0"
  });

  if (error) console.error(error.message);

  const riverZones = data ? data.zonesWithCatch : [];

  return (
    <div className='input-group '>
      <select
        className='custom-select borderSetting'
        id='SelectRiver'
        value={selectedZoneId}
        onChange={changeZone}
      >
        {data && riverZones.length > 0 ? (
          <option value="0">HELE ELVA</option>
        ) : (
          <option disabled value="0">INGEN SONER</option>
        )}
        {!loading && riverZones
          .toSorted(sortZones)
          .map((zone) => {
            return (
              <option key={zone.zoneId} value={zone.zoneId}>
                {zone.zoneName.toUpperCase()}
              </option>
            );
          })}
      </select>
    </div>
  );
};

function sortZones(a, b) {
  if (a.sortOrder !== null && b.sortOrder === null) {
    return -1;
  } else if (a.sortOrder === null && b.sortOrder !== null) {
    return 1;
  }

  if (a.sortOrder === b.sortOrder) return a.zoneName.localeCompare(b.zoneName);
  if (a.sortOrder > b.sortOrder) {
    return 1;
  } else return -1;
}
