import React, {useEffect, useState} from 'react';
import {useQuery} from "@apollo/client";
import {aggregatedSalmonCatchesPerWeekQuery} from "../../utils/QueryGenerator";
import ChartDataText from "../ChartDataText/ChartDataText";

function AggregationTable({riverId, year, mor, soneId}) {
  const {data, error, loading} = useQuery(aggregatedSalmonCatchesPerWeekQuery(riverId, year, mor, soneId), { skip: riverId === "0"})
  const [chartDataText, setChartDataText] = useState([]);

  if (error) {
    console.error(error);
  }

  useEffect(() => {
    if(typeof data !== "undefined") {
      const text = data.aggregatedSpeciesPerWeekPerYearStatistics;
      setChartDataText(text)
    } else {
      setChartDataText([]);
    }
  }, [data, mor, soneId]);

  return (
    <ChartDataText
      graphData={chartDataText}
      year={year}
    ></ChartDataText>
  );
}

export default AggregationTable;