import classNames from "classnames";
import React, { Component, Fragment } from "react";
import { Query } from "@apollo/client/react/components";
import MediaQuery from "react-responsive";
import { getSeasonYear, YearList } from "../../utils/general";
import { aggregatedSalmonCatchesPerWeekQuery } from "../../utils/QueryGenerator";
import ChartDataText from "../ChartDataText/ChartDataText";
import { CountyList } from "../Common/CountyList";
import { RiverList } from "../Common/RiverList";
import { RiverSoneList } from "../Common/RiverSoneList";
import GraphView, { QUERY_TYPES } from "../GraphView/GraphView";
import Header from "../Header";
import StockTable from "../StockTable/StockTable";
import StockTableAggregated from "../StockTableAggregated/StockTableAggregated";
import StockTableWeek from "../StockTableWeek/StockTableWeek";
import AggregationTable from "./AggregationTable";
import UrlStateHandler from "./UrlStateHandler";

class StockPage extends Component {
  state = {};

  initialFilters = {
    properties: {
      released: null
    },
    species: {
      "Laks": false,
      "Sjøørret": false,
      "Sjørøye": false,
      "Regnbueørret": false,
    }
  };

  freshNavigationState = {
    countyId: "0",
    riverId: "0",
    soneId: "0",
    stockOffset: 0,
    aggregateOffset: 0,
    name: "",
    week: 0,
    filters: this.initialFilters,
  };

  initialState = {
    stockOffsetLimit: 100,
    stockLimit: 100,
    aggregateLimit: 100,
    queryId: 1,
    year: getSeasonYear(6, 1),
    tableHeading: "SISTE FANGSTER",
    graphHeading: "ANTALL LAKS PER UKE SISTE 3 ÅR",
    graphQueryNumber: 0,
    oldGraphQueryNumber: 0,
    graphLabelType: "fangstuke",
    oldQueryId: 1,
    mor: false,
    sort: "-DATO",
    weekSearch: false,
    weekTable: false,
    aggregateTable: false,
    salesPageUrl: null,
    ...this.freshNavigationState
  };

  constructor(props) {
    super(props);

    this.state = {
      ...this.initialState,
    };
  }
  componentDidMount() {
    const { riverId } = this.props.match.params;
    this.setState({ riverId });
    this.catchTableRef = React.createRef()
  }
  ChangeFilters = (filters) => {
    this.setState({
      ...this.state,
      filters: filters,
    });
  };
  ChangeRiver = (selectedRiver) => {
    this.ChangeSone({target: {value: "0"}});
    this.setState({
      ...this.freshNavigationState,
      countyId: selectedRiver.countyId,
      riverId: selectedRiver.riverId,
      mor: selectedRiver.isMother,
      salesPageUrl: selectedRiver.url,
    });
  };
  ChangeSone = (e) => {
    let newGraphQueryNumber = this.state.oldGraphQueryNumber;
    const soneId = e.target.value;

    if (soneId !== "0") {
      switch (this.state.oldGraphQueryNumber) {
        case 0:
          newGraphQueryNumber = 10;
          break;
        case QUERY_TYPES.graphAggregatedWeeksSeaTroutWholeRiver:
          newGraphQueryNumber = QUERY_TYPES.graphAggregatedWeeksSeaTroutZone;
          break;
        case 3:
          newGraphQueryNumber = 13;
          break;
        case 6:
          newGraphQueryNumber = 16;
          break;
        case 7:
          newGraphQueryNumber = 17;
          break;
        default:
          break;
      }
    } else {
      switch (
        this.state.oldGraphQueryNumber // check what sort of query was allready active
      ) {
        case 0:
        case 10:
          newGraphQueryNumber = 0;
          break;
        case QUERY_TYPES.graphAggregatedWeeksSeaTroutZone:
          newGraphQueryNumber = QUERY_TYPES.graphAggregatedWeeksSeaTroutWholeRiver;
          break;
        case 3:
        case 13:
          newGraphQueryNumber = 3;
          break;
        case 6:
        case 16:
          newGraphQueryNumber = 6;
          break;
        case 7:
        case 17:
          newGraphQueryNumber = 7;
          break;
        default:
          break;
      }
    }

    if (soneId !== "0") {
      switch (
        this.state.oldQueryId // check what sort of query was allready active
        ) {
        case 1:
        case 11:
          this.setState({
            ...this.freshNavigationState,
            countyId: this.state.countyId,
            riverId: this.state.riverId,
            soneId: soneId,
            oldQueryId: 11,
            queryId: 11,
            offset: 0,
            graphQueryNumber: newGraphQueryNumber,
            oldGraphQueryNumber: newGraphQueryNumber,
          });
          break;
        case 3:
        case 13:
          this.setState({
            ...this.freshNavigationState,
            countyId: this.state.countyId,
            riverId: this.state.riverId,
            soneId: soneId,
            oldQueryId: 13,
            queryId: 13,
            offset: 0,
            graphQueryNumber: newGraphQueryNumber,
            oldGraphQueryNumber: newGraphQueryNumber,
          });
          break;

        case 4:
        case 14:
          this.setState({
            ...this.freshNavigationState,
            countyId: this.state.countyId,
            riverId: this.state.riverId,
            soneId: soneId,
            oldQueryId: 14,
            queryId: 14,
            offset: 0,
            graphQueryNumber: newGraphQueryNumber,
            oldGraphQueryNumber: newGraphQueryNumber,
          });
          break;

        default:
          break;
      }
    } else {
      switch (
        this.state.oldQueryId // check what sort of query was allready active
        ) {
        case 0:
        case 10:
          this.setState({
            ...this.freshNavigationState,
            countyId: this.state.countyId,
            riverId: this.state.riverId,
            soneId: soneId,
            oldQueryId: 0,
            queryId: 0,
            offset: 0,
            graphQueryNumber: newGraphQueryNumber,
            oldGraphQueryNumber: newGraphQueryNumber,
          });
          break;
        case 1:
        case 11:
          this.setState({
            ...this.freshNavigationState,
            countyId: this.state.countyId,
            riverId: this.state.riverId,
            soneId: soneId,
            oldQueryId: 1,
            queryId: 1,
            offset: 0,
            graphQueryNumber: newGraphQueryNumber,
            oldGraphQueryNumber: newGraphQueryNumber,
          });
          break;
        case 3:
        case 13:
          this.setState({
            ...this.freshNavigationState,
            countyId: this.state.countyId,
            riverId: this.state.riverId,
            soneId: soneId,
            oldQueryId: 3,
            queryId: 3,
            offset: 0,
            graphQueryNumber: newGraphQueryNumber,
            oldGraphQueryNumber: newGraphQueryNumber,
          });
          break;

        case 4:
        case 14:
          this.setState({
            ...this.freshNavigationState,
            countyId: this.state.countyId,
            riverId: this.state.riverId,
            soneId: soneId,
            oldQueryId: 4,
            queryId: 4,
            offset: 0,
            graphQueryNumber: newGraphQueryNumber,
            oldGraphQueryNumber: newGraphQueryNumber,
          });
          break;

        default:
          break;
      }
    }
  };

  ChangeSorting = (sortType, asc) => {
    asc
      ? this.setState({ sort: sortType })
      : this.setState({ sort: "-" + sortType });
  };

  ChangeGraphQuery = (graphNumber, labelType, graphHeading) => {
    this.setState({
      graphQueryNumber: graphNumber,
      oldGraphQueryNumber: graphNumber,
      graphLabelType: labelType,
      graphHeading,
    });
  };

  ChangeCounty = (e) => {
    this.setState({ ...this.freshNavigationState, countyId: e.target.value});
  };
  YearChange = (e) => {
    this.setState({
      ...this.freshNavigationState,
      countyId: this.state.countyId,
      riverId: this.state.riverId,
      soneId: this.state.soneId,
      year: Number(e.target.value),
      queryId: this.state.oldQueryId
    });
  };

  goToWeek = (week, year) => {
    const newQueryId =
      this.state.oldQueryId === 4 ? 1 : this.state.oldQueryId === 1 ? 1 : 11;
    if (week !== "Alle") {
      this.setState({
        ...this.freshNavigationState,
        countyId: this.state.countyId,
        riverId: this.state.riverId,
        soneId: this.state.soneId,
        filters: this.state.filters,
        week: week,
        year: year,
        weekTable: true,
        aggregateTable: false,
        weekSearch: true,
        queryId: newQueryId,
        oldQueryId: newQueryId,
        stockOffset: 0,
      });
    } else {
      this.setState({
        countyId: this.state.countyId,
        riverId: this.state.riverId,
        soneId: this.state.soneId,
        week: week,
        year: year,
        weekTable: true,
        aggregateTable: false,
        weekSearch: false,
        queryId: newQueryId,
        oldQueryId: newQueryId,
        stockOffset: 0,
      });
    }
  };

  GetLatestCatches = () => {
    const queryId = parseInt(this.state.soneId) > 0 ? 11 : 1;

    this.setState({
      ...this.freshNavigationState,
      countyId: this.state.countyId,
      riverId: this.state.riverId,
      soneId: this.state.soneId,
      queryId,
      oldQueryId: queryId,
      weekTable: false,
      aggregateTable: false,
      tableHeading: "SISTE FANGSTER",
      sort: "-DATO",
    });
  };

  GetBiggestCatches = () => {
    const queryId = parseInt(this.state.soneId) > 0 ? 13 : 3;

    this.setState({
      ...this.freshNavigationState,
      countyId: this.state.countyId,
      riverId: this.state.riverId,
      soneId: this.state.soneId,
      queryId,
      oldQueryId: queryId,
      weekTable: false,
      aggregateTable: false,
      tableHeading: "STØRSTE FANGSTER",
      sort: "-KG",
    });
  };

  GetAggregateCatches = () => {
    const queryId = parseInt(this.state.soneId) > 0 ? 14 : 4;
    this.setState({
      ...this.freshNavigationState,
      countyId: this.state.countyId,
      riverId: this.state.riverId,
      soneId: this.state.soneId,
      aggregateTable: true,
      weekTable: false,
      queryId,
      oldQueryId: queryId,
      tableHeading: "AGGREGERTE FANGSTER",
    });
  };
  GetWeekbasedCatches = () => {
    const queryId = this.state.soneId > 0 ? 11 : 1;
    this.setState({
      ...this.freshNavigationState,
      countyId: this.state.countyId,
      riverId: this.state.riverId,
      soneId: this.state.soneId,
      queryId,
      oldQueryId: queryId,
      weekTable: true,
      aggregateTable: false,
      tableHeading: "FANGST PER UKE " + this.state.year,
    });
  };
  NameSearch = (e) => {
    if (e.target.value.length > 3) {
      this.setState({
        name: e.target.value,
      });
    } else {
      if (e.target.value.length === 0) {
        this.setState({ name: "" });
      }
    }
  };

  mobileTableChange = (e) => {
    let queryId = 1;
    switch (e.target.value) {
      case "1":
        queryId = this.state.soneId != "0" ? 11 : 1;
        this.setState({
          ...this.freshNavigationState,
          countyId: this.state.countyId,
          riverId: this.state.riverId,
          soneId: this.state.soneId,
          queryId,
          oldQueryId: queryId,
          weekTable: false,
          aggregateTable: false,
          tableHeading: "SISTE FANGSTER",
          sort: "-DATO",
        });
        break;
      case "2":
        queryId = this.state.soneId != "0" ? 13 : 3;
        this.setState({
          ...this.freshNavigationState,
          countyId: this.state.countyId,
          riverId: this.state.riverId,
          soneId: this.state.soneId,
          queryId,
          oldQueryId: queryId,
          weekTable: false,
          aggregateTable: false,
          tableHeading: "STØRSTE FANGSTER",
          sort: "-KG",
        });
        break;
      case "3":
        queryId = this.state.soneId != "0" ? 11 : 1;
        this.setState({
          ...this.freshNavigationState,
          countyId: this.state.countyId,
          riverId: this.state.riverId,
          soneId: this.state.soneId,
          queryId,
          oldQueryId: queryId,
          weekTable: true,
          aggregateTable: false,
          tableHeading: "FANGST PER UKE " + this.state.year,
          name: '',
        });
        break;
      case "4":
        queryId = this.state.soneId != "0" ? 14 : 4;
        this.setState({
          ...this.freshNavigationState,
          countyId: this.state.countyId,
          riverId: this.state.riverId,
          soneId: this.state.soneId,
          aggregateTable: true,
          weekTable: false,
          queryId,
          oldQueryId: queryId,
          tableHeading: "AGGREGERTE FANGSTER"
        });
        break;
      default:
        break;
    }
  };
  paginateRight = () => {
    const newPaginations = this.state.stockOffset + this.state.stockOffsetLimit;
    if (this.catchTableRef.current) {
      this.catchTableRef.current.scrollIntoView()
    }
    this.setState({stockOffset: newPaginations});
  };
  paginateLeft = () => {
    const newPaginations = this.state.stockOffset - this.state.stockOffsetLimit;
    if (this.catchTableRef.current) {
      this.catchTableRef.current.scrollIntoView()
    }
    this.setState({stockOffset: newPaginations});
  };
  aggregatePaginateRight = () => {
    const newPaginations =
      this.state.aggregateOffset + this.state.stockOffsetLimit;
    this.setState({aggregateOffset: newPaginations});
  };

  aggregatePaginateLeft = () => {
    const newPaginations =
      this.state.aggregateOffset - this.state.stockOffsetLimit;
    this.setState({aggregateOffset: newPaginations});
  };

  render() {
    const {
      riverId,
      soneId,
      countyId,
      year,
      queryId,
      tableHeading,
      graphHeading,
      name,
      graphQueryNumber,
      graphLabelType,
      aggregateLimit,
      aggregateOffset,
      stockLimit,
      stockOffset,
      mor,
      sort,
      week,
      weekSearch,
      weekTable,
      aggregateTable,
      filters,
      salesPageUrl
    } = this.state;
    return (
      <Fragment>
        <Header FrontPage={false}></Header>
        <UrlStateHandler riverId={riverId} onChangeRiver={this.ChangeRiver}></UrlStateHandler>
        <MediaQuery minWidth={1224}>
          <div className='container-fluid StockPage'>
            <div className='row StockPageTop justify-content-md-center '>
              <div className='col-4  stockPageOptions'>
                <CountyList
                  SelectedCounty={countyId}
                  ChangeCounty={this.ChangeCounty}
                ></CountyList>

                <RiverList
                  SelectedRiverId={riverId}
                  CountyId={countyId}
                  onChangeRiver={this.ChangeRiver}
                ></RiverList>
                <RiverSoneList
                  changeZone={this.ChangeSone}
                  selectedRiverId={riverId}
                  selectedSoneId={soneId}
                  selectedYear={year}
                ></RiverSoneList>

                {YearList(2016, this.YearChange, year)}

                  <>
                  <div className='stockPageButtons'>
                  <div className='row'>
                    <div className='col'>
                      <a
                        target='__blank'
                        href={salesPageUrl || "https://www.inatur.no/sok/laksefisketilbud"}
                      >
                        <button className='borderSetting'>
                          KJØP FISKEKORT
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <button
                        className={classNames({
                          ButtonSelected: tableHeading.indexOf("SISTE") > -1,
                          borderSetting: true,
                        })}
                        onClick={this.GetLatestCatches}
                      >
                        SISTE FANGSTER
                      </button>
                    </div>
                    <div className='col'>
                      <button
                        className={classNames({
                          ButtonSelected: tableHeading.indexOf("STØRSTE") > -1,
                          borderSetting: true,
                        })}
                        onClick={this.GetBiggestCatches}
                      >
                        STØRSTE FANGSTER
                      </button>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col'>
                      <button
                        className={classNames({
                          ButtonSelected: weekTable,
                          borderSetting: true,
                        })}
                        onClick={this.GetWeekbasedCatches}
                      >
                        FANGST PER UKE
                      </button>
                    </div>
                    <div className='col'>
                      <button
                        className={classNames({
                          ButtonSelected: aggregateTable,
                          borderSetting: true,
                        })}
                        onClick={this.GetAggregateCatches}
                      >
                        AGGREGERT
                      </button>
                    </div>
                  </div>
                </div>

                  </>
                <AggregationTable
                  riverId={riverId}
                  year={year}
                  mor={mor}
                  soneId={soneId}
                />
              </div>
              {/* <div className='col-lg'> */}
              <div className='col-8'>
                <div className='GraphView'>
                  {this.renderStockGraph(riverId, graphQueryNumber, mor, year, graphHeading, soneId)}
                </div>
              </div>
            </div>
            {this.renderStockTable(weekTable, riverId, soneId, year, queryId, mor, name, tableHeading, stockOffset, stockLimit, sort, week, weekSearch, filters, aggregateTable, aggregateLimit, aggregateOffset)}
          </div>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className='StockPage StockPageMobile'>
            <div className='stockPageOptionsMobile'>
              <CountyList
                SelectedCounty={countyId}
                ChangeCounty={this.ChangeCounty}
              ></CountyList>
              <RiverList
                SelectedRiverId={riverId}
                CountyId={countyId}
                onChangeRiver={this.ChangeRiver}
              ></RiverList>
              <RiverSoneList
                changeZone={this.ChangeSone}
                selectedRiverId={riverId}
                selectedSoneId={soneId}
                selectedYear={year}
              ></RiverSoneList>
              {YearList(2016, this.YearChange, year)}
              <div className='fiskeKort borderSetting buttonSetting'>
                <a
                  target='__blank'
                  href={salesPageUrl || "https://www.inatur.no/sok/laksefisketilbud"}
                >
                  FISKEKORT
                </a>
              </div>
            </div>
            <div className='GraphView'>
              {this.renderStockGraph(riverId, graphQueryNumber, mor, year, graphHeading, soneId)}
            </div>
            <div className='TableOptionsMobile noBorderSetting form-group'>
              <select
                className='form-control'
                onChange={this.mobileTableChange}
              >
                <option value='1'>SISTE FANGSTER</option>
                <option value='2'>STØRSTE FANGSTER</option>
                <option value='3'>FANGST PER UKE</option>
                <option value='4'>AGGREGERT</option>
              </select>
            </div>
            {this.renderStockTable(weekTable, riverId, soneId, year, queryId, mor, name, tableHeading, stockOffset, stockLimit, sort, week, weekSearch, filters, aggregateTable, aggregateLimit, aggregateOffset)}
          </div>
        </MediaQuery>
      </Fragment>
    );
  }

  renderStockGraph(riverId, graphQueryNumber, mor, year, graphHeading, soneId) {
    return <GraphView
      riverId={riverId}
      ChangeGraphView={this.ChangeGraphQuery}
      queryNumber={graphQueryNumber}
      mor={mor}
      year={year}
      heading={graphHeading}
      soneId={soneId}
    ></GraphView>;
  }

  renderStockTable(weekTable, riverId, soneId, year, queryId, mor, name, tableHeading, stockOffset, stockLimit, sort, week, weekSearch, filters, aggregateTable, aggregateLimit, aggregateOffset) {
    return <div ref={this.catchTableRef} className='row  StockPageBottom'>
      {weekTable ? (
        <StockTableWeek
          riverId={riverId}
          soneId={soneId}
          year={year}
          name={name}
          NameSearch={this.NameSearch}
          TableHeading={tableHeading}
          offset={stockOffset}
          limit={stockLimit}
          changeSorting={this.ChangeSorting}
          paginateRight={this.paginateRight}
          paginateLeft={this.paginateLeft}
          sort={sort}
          week={week}
          goToWeek={this.goToWeek}
          weekSearch={weekSearch}
          filters={filters}
          changeFilters={this.ChangeFilters}
        ></StockTableWeek>
      ) : aggregateTable ? (
        <StockTableAggregated
          riverId={riverId}
          soneId={soneId}
          year={year}
          queryId={queryId}
          TableHeading={tableHeading}
          limit={aggregateLimit}
          offset={aggregateOffset}
          paginateLeft={this.aggregatePaginateLeft}
          paginateRight={this.aggregatePaginateRight}
          goToWeek={this.goToWeek}
        ></StockTableAggregated>
      ) : (
        <StockTable
          riverId={riverId}
          soneId={soneId}
          year={year}
          name={name}
          NameSearch={this.NameSearch}
          TableHeading={tableHeading}
          offset={stockOffset}
          limit={stockLimit}
          changeSorting={this.ChangeSorting}
          paginateRight={this.paginateRight}
          paginateLeft={this.paginateLeft}
          sort={sort}
          week={week}
          weekSearch={weekSearch}
          filters={filters}
          changeFilters={this.ChangeFilters}
        ></StockTable>
      )}
    </div>;
  }

  renderAggregationTable(riverId, year, mor, soneId) {
    return (
      <Query query={aggregatedSalmonCatchesPerWeekQuery(riverId, year, mor, soneId)} skip={riverId === "0"}>
        {({ loading, error, data }) => {
          if (error || !data)
            console.log(error);

          if (data == undefined || data == null) {
            return (
              <h4 key="0">Kunne ikke hente data... Prøv å laste inn siden på nytt</h4>
            );
          }

          const chartDataText = mor
            ? soneId !== "0"
              ? data.FangstAggregertMorDatterList
              : data.FangstHeleElvenAggregertList
            : soneId !== "0"
              ? data.FiskFangstUkeValdList
              : data.FiskFangstUkeList;

          return (
            <ChartDataText
              graphData={chartDataText}
              year={year}
            ></ChartDataText>
          );
        } }
      </Query>
    );
  }
}

export default StockPage;
