import React, { Fragment } from "react";
import "./CatchFeed.scss";
import { LatestCatchesQuery } from "../../utils/QueryGenerator";
import Popup from "reactjs-popup";
import moment from "moment";
import Camera from "../../images/camera.svg";
import Expander from "../../images/snip.png";
import Contracter from "../../images/snipflipped.png";
import ExpanderGreen from "../../images/snipgreen.png";

import classNames from "classnames";

import MediaQuery from "react-responsive";

import { Query } from "@apollo/client/react/components";
import CatchReport from "../CatchReport/CatchReport";

const LatestCatchesFeed = ({ heading, expanded, type, setExpanded }) => {
  const FETCH_IMAGES = LatestCatchesQuery()
  return (
    <Fragment>
      <MediaQuery minWidth={1224}>
        <div className='CatchFeed'>
          <h2 className='borderSetting'>{heading}</h2>
          <table className='table table-hover'>
            <thead>
              <tr>
                <th scope='col'>Dato</th>
                <th scope='col'>Art</th>
                <th scope='col'>Vekt</th>
                <th scope='col'>Fisker</th>
                <th scope='col'>Elv</th>
                <th scope='col'>Sone</th>
                <th scope='col'>Fangstrapport</th>
              </tr>
            </thead>
            <tbody>
              <Query query={FETCH_IMAGES} >
                {({ loading, error, data }) => {
                  if (loading)
                    return (
                      <tr>
                        <td>Loading...</td>
                      </tr>
                    );
                  if (error) console.log(error);

                  if (data == undefined || data == null) {
                    return (
                      <tr key="0">
                        <td>Kunne ikke hente data... Prøv å laste inn siden på nytt</td>
                      </tr>
                    );
                  }

                  let key = 0;

                  return data.latestCatches.map((CATCH) => {
                    key++;
                    return (
                      <tr key={key}>
                        <td>
                          {moment.unix(CATCH.DATO / 1000).format("DD.MM.YYYY")}
                        </td>
                        <td>{CATCH.ART}</td>
                        <td>{CATCH.KG.toFixed(1)}</td>
                        <td>{CATCH.NAVN}</td>
                        <td>{CATCH.ElvNavn}</td>
                        <td>{CATCH.SONE}</td>

                        <td className='cursorPointer'>
                          <Popup
                            modal
                            nested
                            className='feedWeb'
                            trigger={
                              <span>
                                <span>
                                  ÅPNE
                                  {CATCH.Bilde === "J" ? (
                                    <img src={Camera} alt='cameraicon'></img>
                                  ) : (
                                    <span className='ImageWidth'></span>
                                  )}
                                </span>
                              </span>
                            }
                            position='center'
                          >
                            {(close) => (
                              <Fragment>
                                <p className='close' onClick={close}>
                                  <div className='xClose'>x</div>
                                  <div className='textClose'>LUKK</div>
                                </p>
                                <CatchReport Catch={CATCH}></CatchReport>
                              </Fragment>
                            )}
                          </Popup>
                        </td>
                      </tr>
                    );
                  });
                }}
              </Query>
            </tbody>
          </table>
        </div>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <div className='CatchFeedMobile '>
          <h2
            className='borderSetting heading'
            onClick={() => setExpanded(type)}
          >
            {heading}
            <span className='FeedIcon'>
              {expanded === false ? (
                <img src={Expander} alt='expandicon'></img>
              ) : (
                <img src={Contracter} alt='contracticon'></img>
              )}
            </span>
          </h2>

          {expanded && (
            <table className='table table-hover'>
              <tbody>
                <Query query={FETCH_IMAGES} >
                  {({ loading, error, data }) => {
                    if (loading)
                      return (
                        <tr>
                          <td>Loading...</td>
                        </tr>
                      );
                    if (error) console.log(error);

                    if (data == undefined || data == null) {
                      return (
                        <tr key="0">
                          <td>Kunne ikke hente data... Prøv å laste inn siden på nytt</td>
                        </tr>
                      );
                    }

                    let key = 0;

                    return data.latestCatches.map((CATCH) => {
                      key++;
                      return (
                        <tr
                          key={key}
                          className={classNames({
                            even: !parseInt(key) % 2 === 0,
                            odd: parseInt(key) % 2 === 0,
                          })}
                        >
                          <td>
                            <div className='row'>
                              <div className='col boldFont'>Dato</div>
                              <div className='col alignRight'>
                                {moment
                                  .unix(CATCH.DATO / 1000)
                                  .format("DD.MM.YYYY")}
                              </div>
                            </div>
                            <div className='row'>
                              <div className='col boldFont'>Art</div>
                              <div className='col alignRight'>{CATCH.ART}</div>
                            </div>
                            <div className='row'>
                              <div className='col boldFont'>Vekt</div>
                              <div className='col alignRight'>{CATCH.KG.toFixed(1)}</div>
                            </div>
                            <div className='row'>
                              <div className='col boldFont'>Fisker</div>
                              <div className='col alignRight'>{CATCH.NAVN}</div>
                            </div>
                            <div className='row'>
                              <div className='col boldFont'>Elv</div>
                              <div className='col alignRight'>{CATCH.ElvNavn}</div>
                            </div>
                            <div className='row'>
                              <div className='col boldFont'>Sone</div>
                              <div className='col alignRight'>{CATCH.SONE}</div>
                            </div>
                            <div className='row'>
                              <div className='col boldFont'>Fangstrapport</div>
                              <div className='col alignRight'>
                                <Popup
                                  modal
                                  nested
                                  className='feedMobile'
                                  trigger={
                                    <span className='openReport'>
                                      ÅPNE
                                      <img
                                        className='reportExpanderIcon'
                                        src={ExpanderGreen}
                                        alt='expandicon'
                                      ></img>
                                      <span>
                                        {CATCH.Bilde === "J" ? (
                                          <img src={Camera} alt=""></img>
                                        ) : null}
                                      </span>
                                    </span>
                                  }
                                  position='center'
                                >
                                  {(close) => (
                                    <Fragment>
                                      <p className='close' onClick={close}>
                                        <div className='xClose'>x</div>
                                        <div className='textClose'>LUKK</div>
                                      </p>
                                      <CatchReport Catch={CATCH}></CatchReport>
                                    </Fragment>
                                  )}
                                </Popup>
                              </div>
                            </div>
                          </td>
                        </tr>
                      );
                    });
                  }}
                </Query>
              </tbody>
            </table>
          )}
        </div>
      </MediaQuery>
    </Fragment>
  );
};

export default LatestCatchesFeed;
