export const SortableHeaders = ({
    switchSorting,
    renderCorrectSortArrow
}) => {
    return (
        <thead className='StockTableHeader'>
        <tr>
          <th scope='col' onClick={() => switchSorting("DATO")}>
            {"Dato "}
            <span className='FeedIcon'>
              { renderCorrectSortArrow("DATO") }
            </span>
          </th>
          <th scope='col' onClick={() => switchSorting("KG")}>
            {"Vekt "}
            <span className='FeedIcon'>
              { renderCorrectSortArrow("KG") }
            </span>
          </th>
          <th scope='col' onClick={() => switchSorting("ART")}>
            {"Art "}
            <span className='FeedIcon'>
              { renderCorrectSortArrow("ART") }
            </span>
          </th>
          <th scope='col' onClick={() => switchSorting("Redskap")}>
            {"Redskap "}
            <span className='FeedIcon'>
              { renderCorrectSortArrow("Redskap") }
            </span>
          </th>
          <th scope='col' onClick={() => switchSorting("SONE")}>
            {"Vald/Sone "}
            <span className='FeedIcon'>
              { renderCorrectSortArrow("SONE") }
            </span>
          </th>
          <th scope='col'>Navn</th>
          <th>Bilde</th>
          <th>Satt ut</th>
          <th>Oppdrett</th>
        </tr>
      </thead>
    );
};
