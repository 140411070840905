import React from "react";
import { Query } from "@apollo/client/react/components";
import gql from "graphql-tag";
export const CountyList = ({ ChangeCounty, SelectedCounty }) => {
  // Apollo query
  const COUNTIES_QUERY = gql`
  query CountiesQuery {
    counties {
      Id
      Navn
    }
  }
  `;
  return (
    <Query query={COUNTIES_QUERY} >
      {({ loading, error, data }) => {
        if (loading) return <h4>Loading...</h4>;
        if (error || !data) console.log(error);

        return (
          <div className='input-group'>
            <select
              className='custom-select borderSetting'
              id='SelectCounty'
              value={SelectedCounty}
              onChange={ChangeCounty}
            >
              <option disabled value='alle' key={"alle123"}>
                VELG FYLKE...
              </option>
              {data != undefined ? data.counties.map((county) => {
                return (
                  <option
                    key={county.Id}
                    value={county.Id}
                    name={county.Navn.toUpperCase()}
                    id={county.Id}
                  >
                    {county.Navn.toUpperCase()}
                  </option>
                );
              }) : null}
            </select>
          </div>
        );
      }}
    </Query>
  );
};
