import React, {useEffect, useState} from "react";
import {useQuery} from "@apollo/client";
import "./StockTableAggregated.scss";
import LoadingOverlay from "react-loading-overlay-nextgen";

import {aggregatedCatchQueries} from "../../utils/QueryGenerator";

const StockTableAggregated = ({
                                riverId,
                                year,
                                soneId,
                                TableHeading,
                                goToWeek,
                              }) => {
  const [speciesWithCatches, setSpeciesWithCatches] = useState([]);
  const query = aggregatedCatchQueries(riverId, soneId, year, Object.keys(anadromeSpeciesConfig).map(s => anadromeSpeciesConfig[s].shortHand))
  const {isLoading, data} = useQuery(query, {
    skip: riverId === "0"
   });
  const [catchesAggregatedByWeek, setCatchesAggregatedByWeek] = useState([]);

  useEffect(() => {
    if (riverId === "0") {
      setCatchesAggregatedByWeek([]);
      setSpeciesWithCatches([]);
    }
  }, [riverId]);

  useEffect(() => {
    if (data) {
      setCatchesAggregatedByWeek(createTableDataForWeek(data));
      const existingSpeciesCodes = data.aggregatedWeightAndNumbersPerWeekStatistics.map(it => it.Fangstkode);
      const speciesWithCatches = ALL_SPECIES.filter(species => existingSpeciesCodes.includes(anadromeSpeciesConfig[species].shortHand));
      setSpeciesWithCatches(speciesWithCatches);
    }
  }, [data]);

  return (
    <LoadingOverlay
      active={isLoading}
      spinner
      text='Laster inn fangst...'
      className='loadingOverlay'
    >
      <div className='stockTableAggregated'>
        <table className='table table-hover'>
          <thead>
          <tr>
            <th className='firstTH' colSpan={1 + getTotalNumberOfDataColumns(speciesWithCatches, anadromeSpeciesConfig)}>
              {TableHeading + " " + year}
            </th>
          </tr>
          <tr>
            <th className='subHeader'>
              <span/>
            </th>
            {speciesWithCatches.map(key => {
              return (
                <th className='subHeader' key={key} colSpan={anadromeSpeciesConfig[key].headers.length}>
                  {anadromeSpeciesConfig[key].title.toUpperCase()}
                </th>
              )
            })}
          </tr>
          <tr>
            <th>
              Uke
            </th>
            {speciesWithCatches.map(species => {
              return anadromeSpeciesConfig[species].headers.map((header, key) => {
                return (<th key={`${species}-${key}`} scope='col'>{header}</th>)
              })
            })
            }
          </tr>
          </thead>
          <tbody>
          {catchesAggregatedByWeek.map((row) => {
            return (
              <tr key={row.week}>
                <td
                  className='cursorPointer underline'
                  onClick={() => goToWeek(row.week, year)}
                >
                  {row.week}
                </td>
                {speciesWithCatches.map((species) => {
                  return anadromeSpeciesConfig[species].columns.map((column, index) => {
                    return row[species] !== undefined ? (
                      <td
                        key={`${species}-${column}`}
                        className={index === 0 ? 'speciesColumnDivider' : ''}
                      >
                        {formatValueForTable(row[species][column])}
                      </td>
                    ) : (
                      <td
                        key={`${species}-${column}`}
                        className={index === 0 ? 'speciesColumnDivider' : ''}
                      />
                    )
                  })
                })}
              </tr>)
          })}
          <tr>
            <td></td>
            {createCatchSummary(data, speciesWithCatches).map((column, index) => (
              <td key={index}>{formatValueForTable(column)}</td>
            ))}
          </tr>
          </tbody>
        </table>
      </div>
    </LoadingOverlay>
  );
};

function formatValueForTable(value) {
  const isFloat = typeof(value) === "number" && !Number.isInteger(value);
  if (isFloat) {
    return parseFloat(value).toFixed(1);
  }
  return value;
}

function createTableDataForWeek(data) {
  const salmonData = data.aggregatedWeightAndNumbersPerWeekStatistics.filter(it => it.Fangstkode == anadromeSpeciesConfig.salmon.shortHand);
  const seaTroutData = data.aggregatedWeightAndNumbersPerWeekStatistics.filter(it => it.Fangstkode == anadromeSpeciesConfig.seaTrout.shortHand);
  const charData = data.aggregatedWeightAndNumbersPerWeekStatistics.filter(it => it.Fangstkode == anadromeSpeciesConfig.char.shortHand);
  const rainbowTroutData = data.aggregatedWeightAndNumbersPerWeekStatistics.filter(it => it.Fangstkode == anadromeSpeciesConfig.rainbowTrout.shortHand);

  const allWeeksWithCatch = new Array(
    ...new Set([
      ...salmonData.map(s => s.Uke),
      ...seaTroutData.map(t => t.Uke),
      ...charData.map(c => c.Uke),
      ...rainbowTroutData.map(r => r.Uke)
    ])
  ).sort((a, b) => a - b);
  return allWeeksWithCatch.map(week => {
    return {
      "week": week,
      salmon: mergeWeekData(salmonData.filter(s => s.Uke === week)),
      seaTrout: mergeWeekData(seaTroutData.filter(t => t.Uke === week)),
      char: mergeWeekData(charData.filter(c => c.Uke === week)),
      rainbowTrout: mergeWeekData(rainbowTroutData.filter(r => r.Uke === week))
    }
  });
}

// Usually only one week is returned per species, but sometimes multiple records are returned and we need to merge it into a single data object
function mergeWeekData(weekDataList) {
  if (weekDataList.length === 0) return {};
  if (weekDataList.length === 1) return weekDataList[0]
  const keys = Object.keys(weekDataList[0]);
  const mergedWeekData = {...weekDataList[0]};
  weekDataList.slice(1).forEach(weekData => {
    keys.forEach(key => {
      if (key === "Maks") {
        mergedWeekData[key] = mergedWeekData[key] >= weekData[key] ? mergedWeekData[key] : weekData[key];
      } else {
        mergedWeekData[key] = mergedWeekData[key] + weekData[key]
      }
    })
  })
  return mergedWeekData;
}

function getTotalNumberOfDataColumns(speciesToDisplay, anadromeSpecies) {
  const speciesWithHeaders = speciesToDisplay.map((species) => anadromeSpecies[species].columns.length);
  if (speciesWithHeaders.length === 0) {
    return 0;
  }
  return speciesToDisplay
    .map((species) => anadromeSpecies[species].columns.length)
    .reduce((acc, current) => acc + current)
}

function createCatchSummary(data, speciesWithCatch) {
  return speciesWithCatch.flatMap(species => {
    const speciesConfiguration = anadromeSpeciesConfig[species];
    const allCatchDataForOneSpecies = data?.aggregatedWeightAndNumbersPerWeekStatistics?.filter(it => it.Fangstkode == speciesConfiguration.shortHand) ?? [];
    return speciesConfiguration.columns.map(column => {
      const allValuesForGivenColumn = allCatchDataForOneSpecies.map(d => d[column]);
      if (allValuesForGivenColumn.length === 0) {
        return 0;
      }
      //"Maks" column should give the biggest value, all other columns should give sum of values.
      if (column === "Maks") {
        return allValuesForGivenColumn.sort((a,b) => a-b)[allValuesForGivenColumn.length-1]
      }
      return allValuesForGivenColumn.reduce((acc, val) => acc + val);
    })
  })
}

const anadromeSpeciesConfig = {
  salmon: {
    title: "Laks",
    headers: ["Størst", "Antall", "Samlet vekt", "<3kg", "3-7kg", ">7kg"],
    columns: ["Maks", "ant", "KG", "antkg03", "antkg37", "antkg7"],
    shortHand: "L",
  },
  seaTrout: {
    title: "Sjøørret",
    headers: ["Størst", "Antall", "Samlet vekt"],
    columns: ["Maks", "ant", "KG"],
    shortHand: "S",
  },
  char: {
    title: "Sjørøye",
    headers: ["Størst", "Antall", "Samlet vekt"],
    columns: ["Maks", "ant", "KG"],
    shortHand: "J",
  },
  rainbowTrout: {
    title: "Regnbueørret",
    headers: ["Størst", "Antall", "Samlet vekt"],
    columns: ["Maks", "ant", "KG"],
    shortHand: "R",
  }
}

const ALL_SPECIES = Object.keys(anadromeSpeciesConfig);

export default StockTableAggregated;
