import React, { useEffect, useState } from "react";
import "./ChartDataText.scss";

const ChartDataText = ({ graphData, year }) => {
  const [TotalWeight, setTotalWeight] = useState(0);
  const [TotalCatches, setTotalCatches] = useState(0);
  const [AverageWeight, setAverageWeight] = useState(0);

  useEffect(() => {
    let totalWeight = 0;
    let totalCatches = 0;

    if (typeof graphData !== "undefined") {
      graphData.forEach((data) => {
        totalWeight = totalWeight += parseFloat(data.kg);
        totalCatches = totalCatches += parseFloat(data.ant);
      });

      setTotalWeight(totalWeight);
      setTotalCatches(totalCatches);
      const averageWeight = (totalWeight !== 0 && totalCatches !== 0) ? totalWeight/totalCatches : 0;
      setAverageWeight(averageWeight);
    }
  }, [graphData]);

  return (
    <div className='ChartTable ChartData'>
      <table className='table table-hover borderSetting'>
        <thead className='StockTableHeaderWrapper'>
          <tr>
            <th className='firstTH'>{year}</th>
            <th>Totalvekt laks</th>
            <th>Antall laks</th>
            <th>Snittvekt pr. laks</th>
          </tr>
        </thead>
        <tbody>
          <tr key={"123456"}>
            <td></td>
            <td>{TotalWeight.toFixed(1)}</td>
            <td>{TotalCatches.toFixed(0)}</td>
            <td className='lastTD'>{AverageWeight.toFixed(1)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ChartDataText;
