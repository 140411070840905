import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import MediaQuery from "react-responsive";
import Scroll from "../../images/mobilescroll.svg";
import { getSeasonYear } from "../../utils/general";
import LatestCatchesFeed from "../LatestCatchesFeed/LatestCatchesFeed";
import Counties from "../Counties";
import Footer from "../Footer";
import Header from "../Header";
import ImageCarousel from "../ImageCarousel/ImageCarousel";
import ImageCollageStatic from "../ImageCollageStatic/ImageCollageStatic";
import RiverTable from "../RiverTable/RiverTable";

class LandingPage extends Component {
  state = {
    activeCountySearch: -1,
    SelectedCounty: "INNLANDET",
    searchRiver: "",
    riverOffset: 0,
    riverLimit: 20,
    riverOffsetMobile: 0,
    riverLimitMobile: 5,
    rightPageAvailable: false,
    leftPageAvailable: false,
    latestCatchesExpanded: false,
    biggestCatchesExpanded: false,
    year: getSeasonYear(6, 1)
  };

  componentDidUpdate() {}

  GetRivers = (countyNumber, searchQuery = "") => {
    this.setState({
      riverOffset: 0,
      activeCountySearch: countyNumber,
      searchRiver: searchQuery,
    });
  };
  paginateRight = () => {
    const newPaginations = this.state.riverOffset + this.state.riverLimit;
    this.setState({ riverOffset: newPaginations, leftPageAvailable: true });
  };
  paginateLeft = () => {
    const newPaginations = this.state.riverOffset - this.state.riverLimit;
    const leftPageAvailable = this.state.riverOffset > 0 ? true : false;
    this.setState({ riverOffset: newPaginations, leftPageAvailable });
  };
  paginateRightMobile = () => {
    const newPaginations =
      this.state.riverOffsetMobile + this.state.riverLimitMobile;
    this.setState({
      riverOffsetMobile: newPaginations,
      leftPageAvailable: true,
    });
  };
  paginateLeftMobile = () => {
    const newPaginations =
      this.state.riverOffsetMobile - this.state.riverLimitMobile;
    const leftPageAvailable = this.state.riverOffsetMobile > 0 ? true : false;
    this.setState({ riverOffsetMobile: newPaginations, leftPageAvailable });
  };

  setPaging = (right) => {
    this.setState({ rightPageAvailable: right });
  };
  setExpanded = (type) => {
    switch (type) {
      case "LatestCatch":
        this.setState({
          latestCatchesExpanded: !this.state.latestCatchesExpanded,
          biggestCatchesExpanded: false,
        });
        break;
      case "BiggestCatch":
        this.setState({
          latestCatchesExpanded: false,
          biggestCatchesExpanded: !this.state.biggestCatchesExpanded,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      SelectedCounty,
      activeCountySearch,
      searchRiver,
      riverOffset,
      riverOffsetMobile,
      riverLimit,
      riverLimitMobile,
      latestCatchesExpanded,
      biggestCatchesExpanded,
    } = this.state;
    return (
      <Fragment>
        <Header FrontPage={true}></Header>
        <MediaQuery minWidth={1224}>
          <main className='container LandingPageMain'>
            <div className='container shadowSetting noBorderSetting searchContainer'>
              <div className='row  mt-5'>
                <div className='col-sm TextBox'>
                  <h4>
                    <span>Laksebørs</span> fra Inatur
                  </h4>

                  <hr />
                  <p>
                    Hvor mye laks og sjøørret tas i norske lakselver? På
                    Laksebørsen finner du oversikt over fangster og elver. Du
                    finner også fiskekort her, om du vil prøve fiskelykken i
                    noen av elvene.
                  </p>
                  <p>
                    Velg elven du ønsker å se fangster fra, eller sjekk status
                    på elven du befinner deg i.
                  </p>
                </div>

                <div className='col-sm'>
                  <Counties
                    GetRivers={this.GetRivers}
                    SelectedCounty={SelectedCounty}
                  ></Counties>
                </div>
              </div>
              <div className='row  mt-5'>
                {activeCountySearch !== -1 ? (
                  <RiverTable
                    countyId={activeCountySearch}
                    searchString={searchRiver}
                    offset={riverOffset}
                    limit={riverLimit}
                    paginateLeft={this.paginateLeft}
                    paginateRight={this.paginateRight}
                  ></RiverTable>
                ) : null}
              </div>
            </div>

            <div className='container'>
              <div className='row'>
                <div className='col smallBoxSetting'>
                  <LatestCatchesFeed
                    heading={"SISTE FANGSTER"}
                  ></LatestCatchesFeed>
                </div>
              </div>
            </div>
            <div className='clearBoth'></div>

            <div className='container shadowSetting'>
              <div className='row'>
                <ImageCollageStatic></ImageCollageStatic>
              </div>
            </div>

            <div className='appAdvertisement'>
              <div className='appAdvertisement__content'>
                <h4>
                  Rapportere laksefiske? Last ned vår nye FangstApp
                </h4>
                <p>
                  Inatur FangstApp lar deg rapportere fangst direkte fra elva, også når du ikke har dekning. I tillegg kan du følge med på laksebørsen live for å se hva som fanges.
                </p>
                <h5>
                  Last ned Inaturs FangstApp for laksefiske!
                </h5>
                <p>
                  <a target='_blank' rel='noreferrer' href='https://play.google.com/store/apps/details?id=no.inatur.fangstapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img src='https://play.google.com/intl/en_us/badges/static/images/badges/no_badge_web_generic.png' alt='Tilgjengelig på Google Play' style={{"height": "83px"}}/></a>
                  <a target='_blank' rel='noreferrer' href="https://apps.apple.com/us/app/fangstapp/id1622548015"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/no-no?size=250x83&amp;releaseDate=1653004800&h=b4405e27e26ec3822a45e143381700ef" alt="Tilgjengelig på App Store" style={{"height": "56px"}}></img></a>
                </p>
              </div>
            </div>
          </main>
        </MediaQuery>
        <MediaQuery maxWidth={1224}>
          <div className='landingPageMobile noBorderSetting'>
            <div className=' p-2  mobileTopText mobileBoxSetting'>
              <div className='col-sm TextBox'>
                <h4>
                  <span>Laksebørs</span> fra Inatur
                </h4>

                <hr />
                <p>
                  Hvor mye laks og sjøørret tas i norske lakselver? På
                  Laksebørsen finner du oversikt over fangster og elver. Du
                  finner også fiskekort her, om du vil prøve fiskelykken i noen
                  av elvene.
                </p>
                <p>
                  Velg elven du ønsker å se fangster fra, eller sjekk status på
                  elven du befinner deg i.
                </p>
              </div>
              <p className='ScrollSymbol'>
                <img alt='mobile scroll' src={Scroll}></img>
              </p>
            </div>
            <div className='mobileBoxSetting'>
              <LatestCatchesFeed
                heading={"SISTE FANGSTER"}
                expanded={latestCatchesExpanded}
                type={"LatestCatch"}
                setExpanded={this.setExpanded}
              ></LatestCatchesFeed>
            </div>

            <div className='countySearchMobile'>
              <Counties
                GetRivers={this.GetRivers}
                SelectedCounty={SelectedCounty}
              ></Counties>
            </div>

            {activeCountySearch !== -1 && (
              <div className='riverTableMobile'>
                <RiverTable
                  countyId={activeCountySearch}
                  searchString={searchRiver}
                  offset={riverOffsetMobile}
                  limit={riverLimitMobile}
                  paginateLeft={this.paginateLeftMobile}
                  paginateRight={this.paginateRightMobile}
                ></RiverTable>
              </div>
            )}

<div className='appAdvertisement'>
            <div className='appAdvertisement__content'>
              <h4>
                Rapportere laksefiske? Last ned vår nye FangstApp
              </h4>
              <p>
                Inatur FangstApp lar deg rapportere fangst direkte fra elva, også når du ikke har dekning. I tillegg kan du følge med på laksebørsen live for å se hva som fanges.
              </p>
              <h5>
                Last ned Inaturs FangstApp for laksefiske!
              </h5>
              <p>
                <a target='_blank' rel='noreferrer' href='https://play.google.com/store/apps/details?id=no.inatur.fangstapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img src='https://play.google.com/intl/en_us/badges/static/images/badges/no_badge_web_generic.png' alt='Tilgjengelig på Google Play' style={{"height": "83px"}}/></a>
                <a target='_blank' rel='noreferrer' href="https://apps.apple.com/us/app/fangstapp/id1622548015"><img src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/no-no?size=250x83&amp;releaseDate=1653004800&h=b4405e27e26ec3822a45e143381700ef" alt="Tilgjengelig på App Store" style={{"height": "56px"}}></img></a>
              </p>
            </div>
          </div>
          </div>


        </MediaQuery>
        <Footer></Footer>
      </Fragment>
    );
  }
}
LandingPage.propTypes = {
  backgroundColor: PropTypes.string,
  foregroundColor: PropTypes.string,
};

LandingPage.defaultProps = {
  backgroundColor: null,
  foregroundColor: null,
};

export default LandingPage;
