import { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import LoadingOverlay from "react-loading-overlay-nextgen";
import { toolAvailabilityQueries } from "../../utils/QueryGenerator";
import "./TableFilters.css";

const allSpecies = [
    "Laks",
    "Sjøørret",
    "Sjørøye",
    "Regnbueørret",
];

export const TableFilters = ({
    close,
    filters,
    filtersChanged,
    riverId,
    zoneId,
    year
}) => {
    const [filterSpecies, setFilterSpecies] = useState(filters.species || {});
    const [filterTools, setFilterTools] = useState(filters.tools || {});
    const [filterReleased, setFilterReleased] = useState(filters.properties?.released || null);
    const [availableTools, setAvailableTools] = useState([])

    const { data: toolData, loading: toolLoading, error: toolError } = useQuery(toolAvailabilityQueries(riverId, zoneId, year), {
        fetchPolicy:  "no-cache",
        skip: riverId === "0"
    });

    useEffect(() => {
        if (toolData) {
            setAvailableTools(toolData.toolsUsed.map(it => it.toolName ?? "Ukjent"));
        }
    }, [toolData]);


    const cancel = () => close();
    const saveAndClose = () => {
        filtersChanged({
            species: filterSpecies,
            tools: filterTools,
            properties: {
                released: filterReleased
            }
        });
        close();
    };

    return (
        <div className="filter-page">
            <div className="filter-page__species filter-page-section">
                <h2 className="borderSetting">ARTER</h2>
                {allSpecies.map(species => {
                    return (
                        <div className="form-check">
                            <input
                                className="form-check-input"
                                type="checkbox"
                                id={"filter-species-" + species}
                                key={"filter-species-" + species}
                                value={species}
                                checked={filterSpecies[species]}
                                onChange={() => {
                                    const newState = { ...filterSpecies };
                                    newState[species] = !newState[species];
                                    setFilterSpecies(newState);
                                }}
                            />
                            <label
                                className="form-check-label"
                                for={"filter-species-" + species}
                            >
                                {species}
                            </label>
                        </div>
                    );
                })}
            </div>

            <div className="filter-page__tools filter-page-section">
                <h2 className="borderSetting">FANGSTREDSKAP</h2>
                    <div  style={{"minHeight": "65px"}}>
                    {availableTools.map(tool => {
                        return (
                            <div className="form-check">
                                <input
                                    className="form-check-input "
                                    type="checkbox"
                                    id={"filter-tools-" + tool}
                                    value={tool}
                                    checked={filterTools[tool]}
                                    onChange={() => {
                                        const newState = { ...filterTools };
                                        newState[tool] = !newState[tool];
                                        setFilterTools(newState);
                                    }}
                                />
                                <label
                                    className="form-check-label"
                                    for={"filter-tools-" + tool}
                                >
                                    {tool}
                                </label>
                            </div>
                        );
                    })}
                    </div>
            </div>

            <div className="filter-page__release filter-page-section">
                <h2 className="borderSetting">FANGSTEGENSKAPER</h2>
                <label for="filter-property-release">SATT UT</label>
                <select
                    className="form-control borderSetting custom-select"
                    id="filter-property-release"
                    onChange={(e) => {
                        const value = e.target.value;
                        setFilterReleased(value == "alle" ? null : value == "ja");
                    }}
                    value={filterReleased === null ? "alle" : (filterReleased ? "ja" : "nei")}
                >
                    <option value="alle">Alle</option>
                    <option value="ja">Ja</option>
                    <option value="nei">Nei</option>
                </select>
            </div>

            <div className="filter-page__buttons">
                <button className="button borderSetting" onClick={cancel}>Avbryt</button>
                <button className="button borderSetting button-primary" onClick={saveAndClose}>Vis</button>
            </div>
        </div>
    );
};
