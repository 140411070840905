import React, { Component, Fragment } from "react";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { MapSelector } from "./MapSelector/MapSelector";
import search from "../images/search.svg";
import MediaQuery from "react-responsive";
import { CountyList } from "./Common/CountyList";

class Counties extends Component {
  state = {
    SelectedCounty: "HEDMARK",
    Counties: [],
  };

  // Uses the id from the svg file, where id is the only way to set the name
  OnClick = (countyId) => {
    var optionElement = document.getElementsByName(countyId);
    var mapBtn = document.getElementById("triggerMapBtn");
    mapBtn.click();
    // console.log(optionElement[0].id);
    if (optionElement.length > 0) {
      this.setState({ SelectedCounty: optionElement[0].id, popupOpen: false });
      this.props.GetRivers(optionElement[0].id);
    }

    this.setState({ popupOpen: false });
  };

  componentDidMount() {
    this.setState({ SelectedCounty: this.props.SelectedCounty });
  }

  CountyChange = (e) => {
    this.setState({ SelectedCounty: e.target.value });
    if (e.target.value !== "alle") this.props.GetRivers(e.target.value, "");
  };

  DoRiverSearch = (e) => {
    if (e.target.value.length > 3) {
      this.props.GetRivers(-2, e.target.value);
    }
  };

  render() {
    const { SelectedCounty } = this.state;

    return (
      <Fragment>
        <ul className='list-group CountySearch'>
          <li className='list-group-item'>
            <div className='form-inline my-2 my-lg-0'>
              <input
                className='form-control mr-sm-2'
                type='text'
                placeholder='Søk etter elv'
                onChange={this.DoRiverSearch}
                style={{
                  backgroundImage: `url(${search}`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "right",
                }}
              />
            </div>
          </li>
          <li className='list-group-item'>
            <MediaQuery minWidth={1224}>
              <Popup
                trigger={<button id='triggerMapBtn'> VELG FYLKE I KART</button>}
                modal
                nested
                position='center'
                closeOnDocumentClick
              >
                {(close) => (
                  <Fragment>
                    <p className='close' onClick={close}>
                      <div className='xClose'>x</div>
                      <div className='textClose'>LUKK</div>
                    </p>
                    <MapSelector OnClick={this.OnClick}></MapSelector>
                  </Fragment>
                )}
              </Popup>
            </MediaQuery>
            <MediaQuery maxWidth={1224}>
              <Popup
                className='mobileCountyMap'
                trigger={<button id='triggerMapBtn'> VELG FYLKE I KART</button>}
                modal
                nested
                position='center'
                closeOnDocumentClick
              >
                {(close) => (
                  <Fragment>
                    <p className='close' onClick={close}>
                      <div className='xClose'>x</div>
                      <div className='textClose'>LUKK</div>
                    </p>
                    <MapSelector OnClick={this.OnClick}></MapSelector>
                  </Fragment>
                )}
              </Popup>
            </MediaQuery>
          </li>
          <li className='list-group-item'>
            <CountyList
              SelectedCounty={SelectedCounty}
              ChangeCounty={this.CountyChange}
            ></CountyList>
          </li>
        </ul>
      </Fragment>
    );
  }
}

export default Counties;
