import React from "react";

// makes a dropdown of years starting with minyear and sets chosenyear as default
export function YearList(minYear, onYearChange, chosenYear) {
  const maxYear = new Date().getFullYear();

  let years = [];

  for (var i = minYear; i <= maxYear; i++) {
    years.push(i);
  }

  return (
    <div className='input-group'>
      <select
        className='custom-select borderSetting'
        onChange={onYearChange}
        value={chosenYear}
      >
        {years.map((year) => {
          return (
            <option key={year} value={year}>
              {year}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export function tableLoader(tr, td) {
  let trArray = [];
  let tdArray = [];
  for (var d = 0; d < td; d++) {
    tdArray.push(<td key={d}>...</td>);
  }

  for (var i = 0; i < tr; i++) {
    trArray.push(
      <tr key={"loading" + i}>
        {tdArray.map((td) => {
          return td;
        })}
      </tr>
    );
  }

  return trArray;
}

export function getSeasonYear(seasonStartMonth, seasonStartDay) {
  const currentDate = new Date();
  const month = currentDate.getMonth() + 1;
  const day = currentDate.getDate();
  let chosenYear = 0;
  if ((month > seasonStartMonth) || (month == seasonStartMonth && day >= seasonStartDay)) {
    chosenYear = currentDate.getFullYear();
  } else {
    chosenYear = currentDate.getFullYear() - 1;
  }
  return chosenYear;
}
