import React, { Fragment, useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import LoadingOverlay from "react-loading-overlay-nextgen";
import MediaQuery from "react-responsive";
import { CatchQuery } from "../../utils/QueryGenerator";
import { PaginationButtons } from "../StockTable/PaginationButtons";
import { StockTableBody } from "../StockTable/StockTableBody";
import "./StockTableWeek.scss";
import { StockTableWeekHeading } from "./StockTableWeekHeading";

const StockTableWeek = ({
                          riverId,
                          year,
                          soneId,
                          NameSearch,
                          name,
                          TableHeading,
                          limit,
                          offset,
                          paginateLeft,
                          paginateRight,
                          changeSorting,
                          sort,
                          week,
                          weekSearch,
                          goToWeek,
                          filters,
                          changeFilters,
                        }) => {
  // Apollo query
  const fetchQuery = CatchQuery(
    riverId,
    soneId,
    limit,
    offset,
    year,
    name,
    sort,
    week,
    weekSearch,
    filters,
  )

  const [dataList, setDataList] = useState([]);
  const {loading, error, data} = useQuery(fetchQuery, {fetchPolicy: "no-cache", skip: riverId === "0"});
  const [nameInput, setNameInput] = useState("");

  function onNameInputChange(e) {
    setNameInput(e.target.value);
    NameSearch(e);
  }

  useEffect(() => {
    if (data) { setDataList(loading ? [] : data.catches); }
  }, [data])

  return (
    <Fragment>
      <MediaQuery minWidth={1224}>
        <LoadingOverlay
          active={loading}
          spinner
          text='Laster inn fangst...'
          className='loadingOverlay'
        >
          <div className='StockTable '>
            <table className='table table-hover'>
              <StockTableWeekHeading
                TableHeading={TableHeading}
                NameSearch={onNameInputChange}
                riverId={riverId}
                soneId={soneId}
                year={year}
                isLoading={loading}
                goToWeek={goToWeek}
                changeSorting={changeSorting}
                week={week}
                name={name}
                filters={filters}
                changeFilters={changeFilters}
              />
              <StockTableBody
                dataList={dataList}
                loading={loading}
                error={error}
              />
            </table>
            <PaginationButtons
              offset={offset}
              resultSize={dataList ? dataList.length : 0}
              onPaginateLeft={paginateLeft}
              onPaginateRight={paginateRight}
              pageLimit={limit}
            />
          </div>
        </LoadingOverlay>
      </MediaQuery>
      <MediaQuery maxWidth={1224}>
        <LoadingOverlay
          active={loading}
          spinner
          text='Laster inn fangst...'
          className='loadingOverlay'
        >
          <div className='StockTable StockTableMobile '>
            <table className='table table-hover '>
            <StockTableWeekHeading
                TableHeading={TableHeading}
                NameSearch={onNameInputChange}
                riverId={riverId}
                soneId={soneId}
                year={year}
                isLoading={loading}
                goToWeek={goToWeek}
                changeSorting={changeSorting}
                week={week}
                name={name}
                filters={filters}
                changeFilters={changeFilters}
              />
              <StockTableBody
                dataList={dataList}
                loading={loading}
                error={error}
              />
            </table>
            <PaginationButtons
              offset={offset}
              resultSize={dataList ? dataList.length : 0}
              onPaginateLeft={paginateLeft}
              onPaginateRight={paginateRight}
              pageLimit={limit}
            />
          </div>
        </LoadingOverlay>
      </MediaQuery>
    </Fragment>
  );
};

export default StockTableWeek;
